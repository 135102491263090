import { Container, Row, Col, Button } from "react-bootstrap";
import { ArrowRight } from "react-bootstrap-icons";
import { Link } from "react-router-dom";
import ComponenteNaoEncotrado from "../../componente-nao-econtrado/componente-nao-econtrado";
import placeHolder2 from "../../../arquivos/imagens/place-holder-2.svg";
import "./secao-credenciados.scss";
import { useEffect, useState } from "react";
import { gestor } from "../../../servicos/gestor";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { COLORS } from "../../../temas/cores";
import { type } from "os";
import {
  convertHtmlNodesToString,
  convertStringHtmlToReactHtml,
  getChildNodesFromHtlmAsString,
} from "../../../utilidades/htmlTools";

export type comportamentoSecaoCredenciados = "home";

export default function SecaoCredenciados({
  comportamento,
}: {
  comportamento: comportamentoSecaoCredenciados;
}) {
  type SecaoCredenciadosType = {
    loading: boolean;
    erroAoCarregar: boolean;
  };

  type Dados = {
    section_data: {
      titulo: string;
      descricao: string;
      link: string;
      imagensArray: Array<{
        src: string;
      }>;
    };
  };

  const [estado, setEstado] = useState<SecaoCredenciadosType>({
    loading: false,
    erroAoCarregar: false,
  });

  const [dados, setDados] = useState<Dados>();

  useEffect(() => {
    gestor.getSections("index").then((response) => {
      if (!response[2].section_informations[0]) {
        estado.erroAoCarregar = true;
        setEstado({ ...estado });
        return;
      }

      setDados(response[2].section_informations[0] as Dados);
    });
  }, []);

  if (estado.erroAoCarregar) {
    return null;
  }

  return (
    <section id="SecaoCredenciados">
      {estado.loading ? (
        <Container className="containerLoading">
          <SkeletonTheme
            baseColor={COLORS.skeletonBaseColor}
            highlightColor={COLORS.skeletonHighlightColor}
          >
            <Row>
              <Col lg={6}>
                <Skeleton className="imagemLoading" />
              </Col>
              <Col lg={6}>
                <Skeleton className="titulo" />
                <Skeleton className="texto" count={4} />
              </Col>
            </Row>
          </SkeletonTheme>
        </Container>
      ) : (
        <Container>
          <Row>
            <Col className="containerEscrita" lg={6}>
              <div className="containerTitulo">
                <hr className="barra" />
                <h3 className="titulo">{dados?.section_data.titulo}</h3>
              </div>
              <div className="texto">
                {getResumo(dados?.section_data.descricao ?? "")}
              </div>
              <Link
                to={dados?.section_data.link ?? ""}
                className="link"
                title="credenciados"
              >
                <Button className="botao">
                  Saiba Mais
                  <ArrowRight className="seta" size={30} />
                </Button>
              </Link>
            </Col>
            <Col lg={6} className="imgCredenciado">
              <img
                className="imagem"
                src={dados?.section_data.imagensArray[0].src ?? ""}
                alt={dados?.section_data.titulo}
              />
              <div className="sombra"></div>
            </Col>
          </Row>
        </Container>
      )}
    </section>
  );
}

const getResumo = (htmlAsString: string) => {
  var htmlNodes = getChildNodesFromHtlmAsString(htmlAsString);
  var selectedNodes = htmlNodes.slice(0, 2);
  var selectedNodesAsHtmlString = convertHtmlNodesToString(selectedNodes);
  return convertStringHtmlToReactHtml(selectedNodesAsHtmlString);
};
