import {
  Container,
  Row,
  Col,
  Button,
  Accordion,
  Card,
  useAccordionButton,
} from "react-bootstrap";
import {
  ArrowRight,
  Building,
  ChevronDown,
  CircleSquare,
  Clock,
  Envelope,
  GeoAlt,
  Search,
  Telephone,
  Whatsapp,
} from "react-bootstrap-icons";
import { Link } from "react-router-dom";
import ComponenteNaoEncotrado from "../../componente-nao-econtrado/componente-nao-econtrado";
import placeHolder4 from "../../../arquivos/imagens/place-holder-4.svg";
import imgServico from "../../../arquivos/imagens/SAM-Serv.png";
import "./secao-unidades.scss";
import { useEffect, useState } from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { COLORS } from "../../../temas/cores";
import { gestor } from "../../../servicos/gestor";
import { SectionCredenciadoType } from "../../../modelos/types/type-section-credenciados";
import ComponenteMapaSelecaoEstado from "../../mapa-selecao-estado/mapa-selecao-estado";

export type comportamentoSecaoUnidades = "nossas-unidades" | "credenciados";

type cardCredenciadosType = {
  pais: string;
  estado: string;
  credenciados: Array<SectionCredenciadoType>;
};

type cardNossaUnidadesType = {
  nome: string;
  telefone: string;
  zero: string;
  endereco: string;
  email: string;
  cnpj: string;
  horarioAtendimento: string;
};

type listaDeLocalidadesType = {
  pais: string;
  estados: Array<string>;
};

function HeaderExpandir({
  children,
  eventKey,
  estado,
  titulo,
  subTitulo,
}: any) {
  const decoratedOnClick = useAccordionButton(eventKey, () => { });

  return (
    <div className="headerExpandir" onClick={decoratedOnClick}>
      <div className="tituloIcone">
        <div className="icone">
          <GeoAlt color="red" size={45} />
        </div>
        <div>
          {estado ? <h2 className="titulo">{estado}</h2> : null}
          {titulo ? <h2 className="titulo">{titulo}</h2> : null}
          {subTitulo ? <p className="subTitulo">{subTitulo}</p> : null}
        </div>
      </div>
      <Button className='botao'>
        <span>Saiba Mais</span>
        <ChevronDown className='seta' color="white" size={20} />
      </Button>
    </div>
  );
}

function montaCredenciado(dados: SectionCredenciadoType) {
  return (
    <div>
      <hr />
      <Row>
        <Col className="containerImagem" lg={12}>
          {dados.section_data.imagensArray.at(0)?.src ? (
            <img
              className="imagem"
              src={dados.section_data.imagensArray.at(0)?.src}
              alt="logo"
            />
          ) : null}
        </Col>

        <Col lg={12}>
          {dados.section_data.nomeCredenciado
            ? [
              <h3 key={0} className="titulo">
                {dados.section_data.nomeCredenciado}
              </h3>,
              <hr key={1} />,
            ]
            : null}

          <Row>
            {dados.section_data.whatsapp ? (
              <Col lg={12}>
                <div className="containerInformacao">
                  <div>
                    <Whatsapp color="red" size={40} />
                  </div>
                  <div className="containerEscrita">
                    <span className="titulo">WhatsApp</span>
                    <span>{dados.section_data.whatsapp}</span>
                  </div>
                </div>
              </Col>
            ) : null}

            {dados.section_data.telefone ? (
              <Col lg={12}>
                <div className="containerInformacao">
                  <div>
                    <Telephone color="red" size={40} />
                  </div>
                  <a href={`tel:${dados.section_data.telefone}`} className="containerEscrita">
                    <span className="titulo">Telefone </span>
                    <span>{dados.section_data.telefone}</span>
                  </a>
                </div>
              </Col>
            ) : null}

            {dados.section_data.email ? (
              <Col lg={12}>
                <div className="containerInformacao">
                  <div>
                    <Envelope color="red" size={40} />
                  </div>
                  <a href={`mailto:${dados.section_data.email}`} className="containerEscrita">
                    <span className="titulo">E-mail</span>
                    <span>{dados.section_data.email}</span>
                  </a>
                </div>
              </Col>
            ) : null}

            {dados.section_data.endereco ? (
              <Col lg={12}>
                <div className="containerInformacao">
                  <div>
                    <GeoAlt color="red" size={40} />
                  </div>
                  <div className="containerEscrita">
                    <span className="titulo">Endereço</span>
                    <span>{dados.section_data.endereco}</span>
                    <span>{dados.section_data.complemento}</span>
                  </div>
                </div>
              </Col>
            ) : null}
          </Row>
        </Col>
      </Row>
    </div>
  );
}

function MontaCardsCredenciado({ cards, filtro }: { cards?: Array<cardCredenciadosType>, filtro?: { pais: string; estado: string } }) {

  const [estado, setElementos] = useState<{ elementos: Array<JSX.Element>, chaveAtiva: string }>({ elementos: [], chaveAtiva: "-1" });

  useEffect(() => {


    estado.elementos = [];

    cards?.forEach((card, index) => {
      if (
        filtro &&
        ((filtro.pais && filtro.pais != card.pais) ||
          (filtro.estado && filtro.estado != card.estado))
      ) return;

      var listaDeCidades = card.credenciados.map(credenciado => credenciado.section_data.cidade);

      listaDeCidades = listaDeCidades.filter((cidade, index) => listaDeCidades.indexOf(cidade) == index);

      const listaDeDadosParaAccordion: Array<{
        cidade: string,
        credenciados: Array<SectionCredenciadoType>
      }> = [];

      listaDeCidades.forEach(cidade => {
        listaDeDadosParaAccordion.push(
          {
            cidade: cidade,
            credenciados: card.credenciados.filter(credenciado => credenciado.section_data.cidade === cidade)
          }
        )
      });

      listaDeDadosParaAccordion.forEach((accordion, index) => {
        estado.elementos.push(
          <Accordion.Item key={accordion.cidade} className="accordionItem" eventKey={`${index}`}>
            <Accordion.Header className="accordionHeader" onClick={() => {
              estado.chaveAtiva = estado.chaveAtiva == `${index}` ? '-1' : `${index}`;
              setElementos({ ...estado });
            }}>
              <GeoAlt className="icone" color="red" size={40} />
              {accordion.cidade}
            </Accordion.Header>
            <Accordion.Body className="accordionBody">
              {accordion.credenciados.map((credenciado, index) => <div key={index} >{montaCredenciado(credenciado)}</div>)}
            </Accordion.Body>
          </Accordion.Item>
        );
      });

      estado.chaveAtiva = "-1";
      setElementos({ ...estado });

    });
  }, [filtro])


  return <Accordion className="accordion" activeKey={estado.chaveAtiva}>{estado.elementos}</Accordion>;
}

function montaCardsNossasUnidades(cards?: Array<cardNossaUnidadesType>) {
  const cardElement: Array<JSX.Element> = [];

  cards?.forEach((card, index) => {
    cardElement.push(
      <Card key={index} className="card">
        <Card.Header className="cardHeader">
          <HeaderExpandir
            eventKey={`${index}`}
            titulo={card.nome}
            subTitulo={card.endereco}
          />
        </Card.Header>
        <Accordion.Collapse eventKey={`${index}`} className="show">
          <Card.Body className="cardBody">
            <hr />
            <Row>
              {card.zero ? (
                <Col lg={4}>
                  <div className="containerInformacao servioAtendimento">
                    <img className="imagem" src={imgServico} alt="logo" />
                    <div className="containerEscrita">
                      <span>{card.zero}</span>
                    </div>
                  </div>
                </Col>
              ) : null}
              <Col lg={8}>
                <Row>
                  {card.telefone ? (
                    <Col lg={6}>
                      <div className="containerInformacao">
                        <Telephone color="red" size={40} />
                        <a href={`tel:${card.telefone}`} className="containerEscrita">
                          <span className="titulo">Telefone</span>
                          <span>{card.telefone}</span>
                        </a>
                      </div>
                    </Col>
                  ) : null}

                  {card.endereco ? (
                    <Col lg={6}>
                      <div className="containerInformacao">
                        <GeoAlt color="red" size={40} />
                        <div className="containerEscrita">
                          <span className="titulo">Endereço</span>
                          <span>{card.endereco}</span>
                        </div>
                      </div>
                    </Col>
                  ) : null}

                  {card.email ? (
                    <Col lg={6}>
                      <div className="containerInformacao">
                        <Envelope color="red" size={40} />
                        <a href={`mailto:${card.email}`} className="containerEscrita">
                          <span className="titulo">E-mail</span>
                          <span>{card.email}</span>
                        </a>
                      </div>
                    </Col>
                  ) : null}

                  {card.cnpj ? (
                    <Col lg={6}>
                      <div className="containerInformacao">
                        <Building color="red" size={40} />
                        <div className="containerEscrita">
                          <span className="titulo">CNPJ</span>
                          <span>{card.cnpj}</span>
                        </div>
                      </div>
                    </Col>
                  ) : null}

                  {card.horarioAtendimento ? (
                    <Col lg={6}>
                      <div className="containerInformacao">
                        <Clock color="red" size={40} />
                        <div className="containerEscrita">
                          <span className="titulo">Horario de Atentimento</span>
                          <span>{card.horarioAtendimento}</span>
                        </div>
                      </div>
                    </Col>
                  ) : null}
                </Row>
              </Col>
            </Row>
          </Card.Body>
        </Accordion.Collapse>
      </Card>
    );
  });

  return <Accordion>{cardElement}</Accordion>;
}

export default function SecaoUnidades({
  comportamento,
}: {
  comportamento: comportamentoSecaoUnidades;
}) {
  type SecaoUnidadesType = {
    loading: boolean;
    erroAoCarregar: boolean;
    configuracao: {
      texto?: boolean;
      filtroDeCards?: boolean;
      ListagemCredenciados?: boolean;
      ListagemNossasUnidades?: boolean;
    };
    controle: {
      filtroAtual: {
        pais: string;
        estado: string;
      };
    };
    dados: {
      texto?: string;
      listaDeLocalidades?: Array<listaDeLocalidadesType>;
      cardsCredenciados?: Array<cardCredenciadosType>;
      cardsNossasUnidades?: Array<cardNossaUnidadesType>;
    };
  };

  const [estado, setEstado] = useState<SecaoUnidadesType>({
    loading: true,
    erroAoCarregar: false,
    configuracao: {},
    controle: {
      filtroAtual: {
        pais: "",
        estado: "",
      },
    },
    dados: {},
  });

  useEffect(() => {
    if (comportamento === "credenciados") {
      gestor.getCredenciados().then((resposta) => {
        if (resposta.erro) {
          estado.erroAoCarregar = true;
          setEstado({ ...estado });
        }

        const listaDeCredenciadosResposta =
          resposta.dados?.data?.at(0)?.section_informations;

        let listaGeografica: Array<{
          pais: string;
          estados: Array<string>;
        }> = [];

        let listaDePais: Array<string> = [];

        listaDeCredenciadosResposta?.forEach((credenciado) => {
          listaDePais.push(credenciado.section_data.pais);
        });

        listaDePais = listaDePais.filter(
          (pais, index) => listaDePais.indexOf(pais) === index
        );

        listaDePais.forEach((pais) =>
          listaGeografica.push({ pais: pais, estados: [] })
        );

        listaGeografica.forEach((geografia, index) => {
          listaDeCredenciadosResposta?.forEach((credenciado) => {
            if (credenciado.section_data.pais == geografia.pais) {
              listaGeografica[index].estados.push(
                credenciado.section_data.estado
              );
            }
          });
        });

        listaGeografica.forEach((geografia, index) => {
          listaGeografica[index].estados = listaGeografica[
            index
          ].estados.filter(
            (estado, indexEstado) =>
              listaGeografica[index].estados.indexOf(estado) === indexEstado
          );
        });

        estado.dados.listaDeLocalidades = listaGeografica;

        estado.dados.cardsCredenciados = [];

        listaGeografica.forEach((localidade) => {
          localidade.estados.forEach((estadoGeografico) => {
            estado.dados.cardsCredenciados?.push({
              pais: localidade.pais,
              estado: estadoGeografico,
              credenciados:
                listaDeCredenciadosResposta?.filter(
                  (credenciado) =>
                    credenciado.section_data.pais === localidade.pais &&
                    credenciado.section_data.estado === estadoGeografico
                ) ?? [],
            });
          });
        });

        estado.loading = false;
        estado.controle.filtroAtual = {
          estado: "RS",
          pais: "Brasil"
        }
        setEstado({ ...estado });
      });
    }

    if (comportamento === "nossas-unidades") {
      gestor.getNossasUnidades().then((retorno) => {
        if (retorno.erro) {
          estado.erroAoCarregar = true;
          setEstado({ ...estado });
        }

        estado.dados.cardsNossasUnidades = [];

        retorno.dados?.data?.at(0)?.section_informations.forEach((unidade) => {
          estado.dados?.cardsNossasUnidades?.push({
            nome: unidade.section_data.titulo,
            endereco: unidade.section_data.endereco,
            cnpj: unidade.section_data.cnpj,
            telefone: unidade.section_data.Telefone,
            zero: unidade.section_data.zero,
            email: unidade.section_data.email,
            horarioAtendimento: unidade.section_data.horarioAtendimento,
          });
        });

        estado.loading = false;

        setEstado({ ...estado });
      });
    }
  }, []);

  if (comportamento === "credenciados") {
    estado.configuracao = {
      texto: true,
      filtroDeCards: true,
      ListagemCredenciados: true,
      ListagemNossasUnidades: false,
    };
  }

  if (comportamento === "nossas-unidades") {
    estado.configuracao = {
      texto: false,
      filtroDeCards: false,
      ListagemCredenciados: false,
      ListagemNossasUnidades: true,
    };
  }

  if (estado.erroAoCarregar) {
    return null;
  }

  return (
    <section id="SecaoUnidades">
      <SkeletonTheme>
        <Container>
          {estado.configuracao.texto ? (
            estado.loading ? (
              <Row>
                <Col lg={8} className="containerLoading">
                  <Skeleton className="textoLoading" count={3} />
                </Col>
              </Row>
            ) : (
              <Row>
                <Col lg={8} className="containerTexto">
                  <p className="texto">{estado.dados?.texto}</p>
                </Col>
              </Row>
            )
          ) : null}

          {estado.configuracao.ListagemCredenciados ? (
            estado.loading ? (
              <Row>
                <Col lg={12} className="containerLoading">
                  <Skeleton className="cardLoading" />
                </Col>
                <Col lg={12} className="containerLoading">
                  <Skeleton className="cardLoading" />
                </Col>
              </Row>
            ) : (
              <Row>
                <Col className="containerMapa">
                  <h3>Clique no estado abaixo para poder visualizar os credenciados referentes a cada estado:</h3>
                  <ComponenteMapaSelecaoEstado listaDeEstadoSelecionaveis={estado.dados.listaDeLocalidades?.at(0)?.estados as Array<string>}
                    estadoAtivo={estado.controle.filtroAtual.estado ?? null}
                    callBack={(nome: string) => {
                      estado.controle.filtroAtual = {
                        estado: nome,
                        pais: "Brasil"
                      }
                      setEstado({ ...estado });
                    }} />
                  <Row>
                    {
                      estado.dados?.listaDeLocalidades?.map((localidade, index) => {
                        if (localidade.pais == "Brasil") return;

                        return (
                          <Col lg={4}>
                            <Button key={index} className={`botaoPais ${estado.controle.filtroAtual.pais == localidade.pais ? 'active' : ''}`} onClick={() => {
                              estado.controle.filtroAtual = {
                                estado: "",
                                pais: localidade.pais
                              }
                              setEstado({ ...estado });
                            }}>{localidade.pais}</Button>
                          </Col>)
                      })
                    }
                  </Row>
                </Col>
                <Col lg={6}>
                  <MontaCardsCredenciado
                    cards={estado.dados.cardsCredenciados}
                    filtro={estado.controle.filtroAtual} />
                </Col>
              </Row>
            )
          ) : null}

          {estado.configuracao.ListagemNossasUnidades ? (
            estado.loading ? (
              <Row>
                <Col lg={12} className="containerLoading">
                  <Skeleton className="cardLoading" />
                </Col>
                <Col lg={12} className="containerLoading">
                  <Skeleton className="cardLoading" />
                </Col>
              </Row>
            ) : (
              <Row>
                <Col lg={12}>
                  {montaCardsNossasUnidades(estado.dados.cardsNossasUnidades)}
                </Col>
              </Row>
            )
          ) : null}
        </Container>
      </SkeletonTheme>
    </section>
  );
}
