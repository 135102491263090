import './pagina-blog.scss'
import { Outlet } from 'react-router-dom';
import SecaoBanner from '../../componentes/secoes/secao-banner/secao-banner';
import { useTitle } from '../../utilidades/tittle';

export default function PaginaBlog() {
  window.scroll(0,0);
  useTitle("Blog");
  return (
    <section id="PaginaBlog">
      <SecaoBanner comportamento='blog' />
      <Outlet />
    </section>
  );
}