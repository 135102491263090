import HTMLReactParser from 'html-react-parser';
import { useEffect, useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import Carousel from 'react-multi-carousel';
import { useLocation } from 'react-router-dom';
import { ImagemType } from '../../../modelos/types/type-imagem';
import { PostType } from '../../../modelos/types/type-post';
import { gestor } from '../../../servicos/gestor';
import { COLORS } from '../../../temas/cores';
import ComponenteNaoEncotrado from '../../componente-nao-econtrado/componente-nao-econtrado';
import SecaoBlog from '../secao-blog/secao-blog';
import SecaoPostRelacionados from '../secao-post-relacionados/secao-post-relacionados';
import './secao-post.scss'

export type comportamentoSecaoPost =
  'post' | null;


function montaCarousel(imagens?: Array<ImagemType>) {
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 1
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  };
  const imagensElements: Array<JSX.Element> = [];

  imagens?.forEach((imagem, index) => {
    imagensElements.push(<img key={index} className="imagem" src={imagem.src} alt="imagem" />);
  });

  return (
    <Carousel
      responsive={responsive}
      className='carousel'
      showDots={true}
      dotListClass={`dot${imagens?.length}`}
      removeArrowOnDeviceType={['tablet', 'mobile']}>
      {imagensElements}
    </Carousel>
  );

}

export default function SecaoPost({ comportamento }: { comportamento: comportamentoSecaoPost }) {

  type SecaoPostType = {
    loading: boolean,
    erroAoCarregar: boolean,
    controle:{
      desabilitado?:boolean
    }
    dados: {
      post?: PostType
    }
  }

  const [estado, setEstado] = useState<SecaoPostType>({
    loading: true,
    erroAoCarregar:false,
    controle:{},
    dados:{}
  });

  const local = useLocation();

  useEffect(() => {

    const id = local.pathname.split('/')[3];

    gestor.getPost(id).then((retorno) => {
      
      if(retorno.erro){
        estado.erroAoCarregar = true;
        setEstado({...estado});
        return;
      }

      if(retorno.dados?.disabled){
        estado.controle.desabilitado = true;
        setEstado({...estado});
        return;
      }
      estado.loading = false;
      estado.dados.post = retorno.dados;
      setEstado({...estado});

    })

  }, []);

  const data = new Date(estado.dados.post?.start_date??"");
  const meses = ['janeiro', 'fevereiro', 'março', 'abril', 'maio', 'junho', 'julho', 'agosto', 'setembro', 'outubro', 'novembro', 'dezembro'];

  return (
    <section id="SecaoPost">

     {estado.loading ? 
     <Container className="containerLoading">
      <SkeletonTheme baseColor={COLORS.skeletonBaseColor} highlightColor={COLORS.skeletonHighlightColor}>
        <Row>
          <Col lg={8}>
            <Skeleton className="imagemLoading"/>
            <Skeleton className="descricaoLoading"/>
            <Skeleton className="tituloLoading"/>
            <Skeleton count={16}/>
          </Col>
          <Col lg={4}>
            {<SecaoPostRelacionados comportamento='post'/>}
          </Col>
        </Row>
      </SkeletonTheme>
     </Container>
     :
      <Container>
        <Row>
          <Col className='containerPost' lg={8}>
            {montaCarousel(estado.dados.post?.imagensArray)}
            <div className="containerDataCategoria">
              <p>{`${data.getDay()} ${meses[data.getMonth()]} ${data.getFullYear()} `}</p>
              <p className='categoria'>{estado.dados.post?.category}</p>
            </div>
            <h1 className='titulo'>{estado.dados.post?.title}</h1>
            {HTMLReactParser(estado.dados.post?.description??"")}
          </Col>
          <Col lg={4}>
            {<SecaoPostRelacionados comportamento='post'/>}
          </Col>
        </Row>
      </Container>}

    </section>
  );
}