import { IDeserializable } from "./deserializable";
import { Image } from "./image"
import { SectionCredenciadoType } from "./types/type-section-credenciados";
import { sectionDataType } from "./types/type-section-nossa-unidades";

export class SectionData implements IDeserializable {

    subsections: any[] = []

    section_informations: ({
        section_data?: {
            link?: string
            titulo?: string
            descricao?: string
            imagensArray?: Image[]
        }
        arquivosArray?: any[]
    })[] = []


    deserialize(input: any): this {
        Object.assign(this, input)
        return this
    }
}

export class SectionDataIndex implements IDeserializable {

    subsections: any[] = []

    section_informations: ({
        section_data?: {
            titulo?: string
            descricao?: string
            imagensArray?: Image[]
            arquivosArray?: any[]
        }
    })[] = []


    deserialize(input: any): this {
        Object.assign(this, input)
        return this
    }
}


export default class SectionNossaUnidadesModel implements IDeserializable {

    dados?: {
        data?: Array<{
            section_informations:Array<sectionDataType>
        }>
        total?: number
    }

    erro?: boolean;

    deserialize(input: any): this {
        Object.assign(this, input);
        return this;
    }

}

export class SectionCredenciadosModel implements IDeserializable {

    dados?: {
        data?: Array<{
            section_informations:Array<SectionCredenciadoType>
        }>
        total?: number
    }

    erro?: boolean;

    deserialize(input: any): this {
        Object.assign(this, input);
        return this;
    }

}