import './secao-cabecalho.scss'
import { Navbar, Container, Nav, NavDropdown, Offcanvas, Button, NavLink } from 'react-bootstrap';
import logo from '../../../arquivos/imagens/logo.svg'
import { Link, Navigate } from 'react-router-dom';
import { Children, useEffect, useState } from 'react';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import { COLORS } from '../../../temas/cores';
import { gestor } from '../../../servicos/gestor';
import { MenuTree } from '../../../modelos/menu';
import Informacoes from '../../../modelos/informacoes';
import React from 'react';
import * as Icon from 'react-bootstrap-icons';

import { useNavigate } from 'react-router-dom';

export default function SecaoCabecalho() {

  let navigate = useNavigate()

  const [logoSrc, setLogoSrc] = useState<string>()
  const [menus, setMenus] = useState<MenuTree[]>([])

  useEffect(() => {
    gestor.getMenus().then(response => {
      setMenus(response)
    })

    gestor.getInformacoes().then(response => {
      setLogoSrc(response.image)
    })
  }, [])

  const [showSideBar, setShowSideBar] = React.useState<boolean>(false)

  const swapShowSideBar = () => {
    setShowSideBar(!showSideBar)
  }

  useEffect(() => {
    function handleResize() {
      if (window.innerWidth > 991.98) {
        setShowSideBar(false)
      }
    }

    window.addEventListener('resize', handleResize)
    handleResize()
  }, [])

  const HandlerSideBar = () => {
    return (
      <button className='handler' onClick={() => swapShowSideBar()}>
        <Icon.XLg />
      </button>
    )
  }

  const navegarPara = (to: string) => {
    setShowSideBar(false)
    navigate(to)
  }

  return (
    <section id="SecaoCabecalho">
      <div className="menu">
        <Navbar expand="lg" expanded={showSideBar}>
          <Container>

            {!logoSrc ?
              <SkeletonTheme baseColor={COLORS.skeletonBaseColor} highlightColor={COLORS.skeletonHighlightColor}>
                <Skeleton className='logo loading' />
              </SkeletonTheme>
              :
              <Navbar.Brand href="/">
                <img src={logoSrc} alt="logo" className='logo' />
              </Navbar.Brand>
            }

            <Navbar.Toggle aria-controls="basic-navbar-nav" onClick={() => swapShowSideBar()} />

            <Navbar.Offcanvas id="Cabecalho" placement='end'>
              <Offcanvas.Header><HandlerSideBar /></Offcanvas.Header>
              <Offcanvas.Body className="cabecalhoBody" >

                {!menus.length ?
                  <SkeletonTheme baseColor={COLORS.skeletonBaseColor} highlightColor={COLORS.skeletonHighlightColor}>
                    <Skeleton className='loadingMenu' />
                  </SkeletonTheme>
                  : CreateMenus(menus, setShowSideBar, navegarPara)
                }

              </Offcanvas.Body>
            </Navbar.Offcanvas>
          </Container>
        </Navbar>
      </div>
    </section>
  );
}

const CreateMenus = (menus: MenuTree[], setShowSideBar: (v: boolean) => void, navegarPara: (to: string) => void): JSX.Element[] => {


  const retorno = menus?.map((menuT: MenuTree, idx: number) => {
    if (menuT.childrens.length) {
      return (
        <NavDropdown title={menuT.menu.name} className='navItem' key={idx}>
          {
            menuT.childrens.map((children: MenuTree, idxChildren: number) =>
              <NavDropdown.Item title={children.menu.name} className='link' key={idxChildren} onClick={() => navegarPara(children.menu.html ?? '/')}>
                <span className='textDropDown'>
                  {children.menu.name}
                </span>
              </NavDropdown.Item>
            )
          }
        </NavDropdown>
      )
    } else {
      return (
        <Nav.Item className='navItem' key={idx}>
          {
            menuT.menu.name === 'E-commerce' ?
              <a href={menuT.menu.html ? menuT.menu.html : ''} title={menuT.menu.name} className='link' target="_blank" onClick={() => setShowSideBar(false)}>
                <span>
                  {menuT.menu.name}
                </span>
              </a> :
              <Link to={menuT.menu.html ? menuT.menu.html : ''} title={menuT.menu.name} className='link' onClick={() => setShowSideBar(false)}>
                <span>
                  {menuT.menu.name}
                </span>
              </Link>
          }
        </Nav.Item>
      )
    }
  });

  retorno.splice(retorno.length-1, 0,
    <Nav.Item className='navItem' key="Tr">
      <a href="/trabalhe-conosco" title="Trabalhe Conosco" className='link trabalhe-conosco' onClick={() => setShowSideBar(false)}>
        <span>
          Trabalhe Conosco
        </span>
      </a>
    </Nav.Item>)

  return retorno;
}