import { Alert, Button, Col, Container, Form, Row } from "react-bootstrap";
import img from "../../../arquivos/imagens/imgTrabalheConosco.png";
import { ArrowRight, Grid } from "react-bootstrap-icons";
import { Link } from "react-router-dom";
import * as Icon from "react-bootstrap-icons";
import "./secao-formulario.scss";

import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { COLORS } from "../../../temas/cores";
import 'react-loading-skeleton/dist/skeleton.css'
import { useEffect, useState } from "react";
import { gestor } from "../../../servicos/gestor";

import ReCAPTCHA from "react-google-recaptcha";
import { ContatoStatus, ContatoTrabalheConoscoType, ContatoType } from "../../../modelos/types/types-contato";

enum PostFormStatus {
  ENVIADO = 'ENVIADO',
  ENVIANDO = 'ENVIANDO',
  ERRO = 'ERRO',
  NULL = 'NULL'
}

export type comportamentoSecaoFormulario =
  | "fale-conosco"
  | "trabalhe-conosco"
  | null;

type FormEstadoFC = {
  nome?: boolean,
  telefone?: boolean,
  email?: boolean,
  cidade?: boolean,
  estado?: boolean,
  pais?: boolean,
  assunto?: boolean,
  descricao?: boolean,
  captcha?: boolean
}

type FormValoresFaleConosco = {
  nome: string,
  telefone: string,
  email: string,
  cidade: string,
  estado: string,
  pais: string,
  assunto: string,
  descricao: string,
  captcha: string
}

type FormValoresTrabalheConosco = {
  nome: string,
  telefone: string,
  email: string,
  dataNascimento: string,
  areaInteresse: string,
  files: File[],
  captcha: string
}

type FormEstadoTC = {
  nome?: boolean,
  telefone?: boolean,
  email?: boolean,
  dataNascimento?: boolean,
  areaInteresse?: boolean,
  files?: boolean,
  captcha?: boolean
}

const validarTelefone = (numero?: string) => {
  const regex = new RegExp('^\\(?[0-9]{2}\\)? ?9?[0-9]{4}-?[0-9]{4}$');

  if (regex.test(numero as string)) return true;

  return false;
}

const validarEmail = (email?: string) => {
  const regex = new RegExp('^[a-z0-9.]+@[a-z0-9]+\.[a-z]+\.([a-z]+)?$');
  if (regex.test(email?.toLowerCase() as string)) return true;
  return false;
}

const validarDataNascimento = (date?: string) => {
  const regex = new RegExp(/(\d{2})\/(\d{2})\/(\d{2})/)

  if (regex.test(date as string)) {

    const dates = date?.split('/')

    if (
      dates && (dates[0] && Number(dates[0]) > 0 && Number(dates[0]) <= 31) &&
      (dates[1] && Number(dates[1]) > 0 && Number(dates[1]) <= 12) &&
      (dates[2] && Number(dates[2]) > 1900 && Number(dates[2]) <= new Date().getFullYear())
    ) return true
    else return false

  }
  return false
}

const mascararTelefone = (tel: string) => {
  var telMasked = tel.replace(/[^\d]/g, '')
  if (telMasked.length <= 9)
    telMasked = telMasked.replace(/(\d{2})(\d)/, "($1) $2");
  else
    telMasked = telMasked.replace(/(\d{2})(\d{5}|\d{4})(\d{4})/, "($1) $2-$3");

  if (telMasked.length > 15) telMasked = telMasked.substring(0, 15);
  return telMasked;
}

const mascararDateBorn = (date: string) => {
  var dateMasked = date.replace(/[^\d]/g, '')
  if (dateMasked.length > 8) dateMasked = dateMasked.substring(0, 8)
  if (dateMasked.length < 5) dateMasked = dateMasked.replace(/(\d{2})(\d)/, "$1/$2")
  else dateMasked = dateMasked.replace(/(\d{2})(\d{2})(\d)/, "$1/$2/$3")
  return dateMasked
}

export default function SecaoFormulario({
  comportamento,
}: {
  comportamento: comportamentoSecaoFormulario;
}) {

  return (
    <SkeletonTheme baseColor={COLORS.skeletonBaseColor} highlightColor={COLORS.skeletonHighlightColor}>
      <section id="SecaoFormulario">
        {comportamento === "fale-conosco" ? <FormularioFaleConosco /> : <></>}
        {comportamento === "trabalhe-conosco" ? <FormularioTrabalheConosco /> : <></>}
      </section>
    </SkeletonTheme>
  );
}


const FormularioFaleConosco = () => {
  const resetValores = (): FormValoresFaleConosco => {
    return { nome: '', telefone: '', email: '', cidade: '', estado: '', pais: '', assunto: '', descricao: '', captcha: '' }
  }

  const [estado, setEstado] = useState<FormEstadoFC>({})
  const [formValores, setFormValores] = useState<FormValoresFaleConosco>(resetValores())
  const [postFormSuccess, setPostFormStatus] = useState<PostFormStatus>(PostFormStatus.NULL)
  const [captchaSiteKey, setCaptchaSiteKey] = useState<string>()

  useEffect(() => {
    gestor.getInformacoes().then(response => {
      setCaptchaSiteKey(response.captcha_site_key)
    })
  }, [])

  const setTelefone = (e: any) => {
    setEstado({ ...estado, telefone: undefined })
    setFormValores({ ...formValores, telefone: mascararTelefone(e?.target?.value) })
  }

  const setEmail = (e: any) => {
    setEstado({ ...estado, email: undefined })
    setFormValores({ ...formValores, email: e?.target?.value })
  }

  const setNome = (e: any) => {
    setEstado({ ...estado, nome: undefined })
    setFormValores({ ...formValores, nome: e?.target?.value })
  }

  const setCidade = (e: any) => {
    setEstado({ ...estado, cidade: undefined })
    setFormValores({ ...formValores, cidade: e?.target?.value })
  }

  const setEstate = (e: any) => {
    setEstado({ ...estado, estado: undefined })
    setFormValores({ ...formValores, estado: e?.target?.value })
  }

  const setPais = (e: any) => {
    setEstado({ ...estado, pais: undefined })
    setFormValores({ ...formValores, pais: e?.target?.value })
  }

  const setAssunto = (e: any) => {
    setEstado({ ...estado, assunto: undefined })
    setFormValores({ ...formValores, assunto: e?.target?.value })
  }

  const setDescricao = (e: any) => {
    setEstado({ ...estado, descricao: undefined })
    setFormValores({ ...formValores, descricao: e?.target?.value })
  }

  const setCaptcha = (token?: string) => {
    setFormValores({ ...formValores, captcha: token ?? '' })
    setEstado({ ...estado, captcha: !!token })
  }

  const enviarForm1 = () => {

    var estadoAux = {
      nome: formValores.nome.length >= 3,
      email: validarEmail(formValores.email),
      telefone: validarTelefone(formValores.telefone),
      assunto: formValores.assunto.length >= 3,
      cidade: formValores.cidade.length >= 3,
      estado: formValores.estado.length >= 3,
      pais: formValores.pais.length >= 3,
      descricao: formValores.descricao.length >= 3,
      captcha: !!estado.captcha
    }

    setEstado({ ...estadoAux })

    for (let [, value] of Object.entries(estadoAux))
      if (!value) return

    setPostFormStatus(PostFormStatus.ENVIANDO)

    var post: ContatoType = {
      email: formValores.email,
      nome: formValores.nome,
      cidade: formValores.cidade,
      uf: formValores.estado,
      assunto: formValores.assunto,
      mensagem: formValores.descricao,
      telefone: formValores.telefone,
      captcha: formValores.captcha,
      pais: formValores.pais,
    }

    gestor.postContato(post).then(response => {
      setPostFormStatus(response === ContatoStatus.OK ? PostFormStatus.ENVIADO : PostFormStatus.ERRO)

      setFormValores(resetValores())
      setEstado({})

      setTimeout(() => setPostFormStatus(PostFormStatus.NULL), 4500)
    })
  }
  return (
    <Container>
      <Row>
        <Form>
          <Row>
            <Col lg={12}>
              <h3 className="texto">Entre em contato com Moreflex</h3>
            </Col>
            <Col lg={6} className="boxEsquerdo">
              <Form.Group className="mb-3">
                <Form.Control
                  className="formCampo"
                  required
                  type="text"
                  placeholder="Seu nome"
                  value={formValores.nome}
                  onChange={setNome}
                  isValid={estado.nome}
                  isInvalid={estado.nome != undefined ? !estado.nome : undefined}
                  onBlur={() => setEstado({ ...estado, nome: formValores.nome.length >= 3 })}
                />

              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Control
                  className="formCampo"
                  required
                  type="text"
                  placeholder="Seu telefone"
                  value={formValores.telefone}
                  onChange={setTelefone}
                  isInvalid={estado.telefone != undefined ? !estado.telefone : undefined}
                  isValid={estado.telefone}
                  onBlur={() => setEstado({ ...estado, telefone: validarTelefone(formValores.telefone) })}
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Control
                  className="formCampo"
                  required
                  type="email"
                  placeholder="Seu email"
                  value={formValores.email}
                  onChange={setEmail}
                  isInvalid={estado.email != undefined ? !estado.email : undefined}
                  isValid={estado.email}
                  onBlur={() => setEstado({ ...estado, email: validarEmail(formValores.email) })}
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Control
                  className="formCampo"
                  required
                  type="text"
                  placeholder="Cidade"
                  value={formValores.cidade}
                  onChange={setCidade}
                  isValid={estado.cidade}
                  isInvalid={estado.cidade != undefined ? !estado.cidade : undefined}
                  onBlur={() => setEstado({ ...estado, cidade: formValores.cidade.length >= 3 })}
                />
              </Form.Group>
              <Row>
                <Col lg={6}>
                  <Form.Group>
                    <Form.Control
                      className="formCampo mb-3 estado"
                      required
                      type="text"
                      placeholder="Estado"
                      value={formValores.estado}
                      onChange={setEstate}
                      isValid={estado.estado}
                      isInvalid={estado.estado != undefined ? !estado.estado : undefined}
                      onBlur={() => setEstado({ ...estado, estado: formValores.estado.length >= 3 })}
                    />
                  </Form.Group>
                </Col>
                <Col lg={6}>
                  <Form.Group>
                    <Form.Control
                      className="formCampo mb-3 pais"
                      required
                      type="text"
                      placeholder="Pais"
                      value={formValores.pais}
                      onChange={setPais}
                      isValid={estado.pais}
                      isInvalid={estado.pais != undefined ? !estado.pais : undefined}
                      onBlur={() => setEstado({ ...estado, pais: formValores.pais.length >= 3 })}
                    />
                  </Form.Group>
                </Col>
              </Row>
            </Col>

            <Col lg={6}>
              <Form.Group className="mb-4">
                <Form.Control
                  className="formCampo"
                  required
                  type="text"
                  placeholder="Assunto"
                  value={formValores.assunto}
                  onChange={setAssunto}
                  isValid={estado.assunto}
                  isInvalid={estado.assunto != undefined ? !estado.assunto : undefined}
                  onBlur={() => setEstado({ ...estado, assunto: formValores.assunto.length >= 3 })}
                />
              </Form.Group>
              <Form.Group controlId="exampleForm.ControlTextarea1">
                <Form.Control
                  as="textarea"
                  rows={7}
                  placeholder="Descrição"
                  value={formValores.descricao}
                  onChange={setDescricao}
                  isValid={estado.descricao}
                  isInvalid={estado.descricao != undefined ? !estado.descricao : undefined}
                  onBlur={() => setEstado({ ...estado, descricao: formValores.descricao.length >= 3 })}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col xs={12} lg={6} className="mt-3 ReCaptcha">
              {captchaSiteKey && <ReCAPTCHA
                sitekey={captchaSiteKey}
                onChange={(e: any) => { setCaptcha(e) }}
                onErrored={() => {
                  setCaptcha(undefined)
                  setEstado({ ...estado, captcha: undefined })
                }}
                onExpired={() => {
                  setCaptcha(undefined)
                  setEstado({ ...estado, captcha: undefined })
                }}
              />}
            </Col>
            <Col xs={12} lg={6} className="mt-3 itemBotao">
              <Button className="botao" onClick={() => enviarForm1()}>
                Enviar <ArrowRight className="seta" size={30} />
              </Button>
            </Col>
          </Row>
        </Form>
      </Row>
      {estado.captcha == false ?
        <Row>
          <Col lg={12}>
            <Alert className="alert mt-3" variant="secondary">
              Por favor responda o ReCaptcha !
            </Alert>
          </Col>
        </Row> : null}
      {postFormSuccess != PostFormStatus.NULL ?
        <Row>
          <Col lg={12}>
            <Alert className="alert mt-3" variant={
              postFormSuccess === PostFormStatus.ENVIADO ? 'success' : postFormSuccess === PostFormStatus.ENVIANDO ? 'primary' : 'danger'}>
              {postFormSuccess === PostFormStatus.ENVIADO ? 'Formulário enviado com sucesso !' :
                postFormSuccess === PostFormStatus.ENVIANDO ? 'Enviando ...' : 'Houve um erro ao enviar o formulário, por favor, tente novamente mais tarde!'}
            </Alert>
          </Col>
        </Row> : null}
    </Container>
  )
}

const FileListToFileArray = (fileList: FileList): File[] => {
  var files: File[] = []

  for (let idx = 0; idx < fileList.length; idx++) {
    files.push(fileList[idx])
  }

  return files
}

const FormularioTrabalheConosco = () => {

  const resetValores = () => {
    return { nome: '', telefone: '', email: '', dataNascimento: '', areaInteresse: '', files: [], captcha: '' }
  }

  const [estado, setEstado] = useState<FormEstadoTC>({})
  const [formValores, setFormValores] = useState<FormValoresTrabalheConosco>(resetValores())

  const [captchaSiteKey, setCaptchaSiteKey] = useState<string>()

  useEffect(() => {
    gestor.getInformacoes().then(response => {
      setCaptchaSiteKey(response.captcha_site_key)
    })
  }, [])

  const [postFormSuccess, setPostFormStatus] = useState<PostFormStatus>(PostFormStatus.NULL)

  const setCaptcha = (token?: string) => {
    setFormValores({ ...formValores, captcha: token ?? '' })
    setEstado({ ...estado, captcha: !!token })
  }

  useEffect(() => setEstado({ ...estado, files: formValores.files.length > 0 ? true : undefined }), [formValores.files])

  const setTelefone = (e: any) => {
    setEstado({ ...estado, telefone: undefined })
    setFormValores({ ...formValores, telefone: mascararTelefone(e?.target?.value) })
  }

  const setEmail = (e: any) => {
    setEstado({ ...estado, email: undefined })
    setFormValores({ ...formValores, email: e?.target?.value })
  }

  const setNome = (e: any) => {
    setEstado({ ...estado, nome: undefined })
    setFormValores({ ...formValores, nome: e?.target?.value })
  }

  const setDataNascimento = (e: any) => {
    setEstado({ ...estado, dataNascimento: undefined })
    setFormValores({ ...formValores, dataNascimento: mascararDateBorn(e?.target?.value) })
  }

  const setAreaInteresse = (e: any) => {
    setEstado({ ...estado, areaInteresse: undefined })
    setFormValores({ ...formValores, areaInteresse: e?.target?.value })
  }

  const enviarForm2 = () => {
    var estadoAux: FormEstadoTC = {
      nome: formValores.nome.length >= 3,
      email: validarEmail(formValores.email),
      telefone: validarTelefone(formValores.telefone),
      dataNascimento: validarDataNascimento(formValores.dataNascimento),
      areaInteresse: formValores.areaInteresse.length >= 2,
      // files: formValores.files.length > 0,
      captcha: !!formValores.captcha.length
    }

    setEstado({ ...estadoAux })

    for (let [, value] of Object.entries(estadoAux))
      if (!value) return

    setPostFormStatus(PostFormStatus.ENVIANDO)

    var post: FormData = new FormData()

    post.append('nome', formValores.nome)
    post.append('email', formValores.email)
    post.append('telefone', formValores.telefone)
    post.append('dataNascimento', formValores.dataNascimento)
    post.append('areaInteresse', formValores.areaInteresse)
    post.append('captcha', formValores.captcha)
    post.append('file', formValores.files[0])

    gestor.postTrabalheConosco(post).then(response => {
      setPostFormStatus(response === ContatoStatus.OK ? PostFormStatus.ENVIADO : PostFormStatus.ERRO)

      setFormValores(resetValores())
      setEstado({})

      setTimeout(() => setPostFormStatus(PostFormStatus.NULL), 4500)
    })
  }

  return (
    <Container>
      <Row>
        <Form>
          <Row>
            <Col lg={12}>
              <h3 className="texto">
                Faça parte de nossa equipe. Envie o seu Curriculum para
                futuras oportunidades na Moreflex.
              </h3>
            </Col>
            <Col lg={6} className="boxEsquerdo">
              <Form.Group className="mb-3">
                <Form.Control
                  className="formCampo"
                  required
                  type="text"
                  placeholder="Seu nome"
                  value={formValores.nome}
                  onChange={setNome}
                  isValid={estado.nome}
                  isInvalid={estado.nome != undefined ? !estado.nome : undefined}
                  onBlur={() => setEstado({ ...estado, nome: formValores.nome.length >= 3 })}
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Control
                  className="formCampo"
                  required
                  type="text"
                  placeholder="Seu telefone"
                  value={formValores.telefone}
                  onChange={setTelefone}
                  isValid={estado.telefone}
                  isInvalid={estado.telefone != undefined ? !estado.telefone : undefined}
                  onBlur={() => setEstado({ ...estado, telefone: validarTelefone(formValores.telefone) })}
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Control
                  className="formCampo"
                  required
                  type="email"
                  placeholder="Seu email"
                  value={formValores.email}
                  onChange={setEmail}
                  isValid={estado.email}
                  isInvalid={estado.email != undefined ? !estado.email : undefined}
                  onBlur={() => setEstado({ ...estado, email: validarEmail(formValores.email) })}
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Control
                  className="formCampo"
                  required
                  type="text"
                  placeholder="Data de nascimento"
                  value={formValores.dataNascimento}
                  onChange={setDataNascimento}
                  isValid={estado.dataNascimento}
                  isInvalid={estado.dataNascimento != undefined ? !estado.dataNascimento : undefined}
                  onBlur={() => setEstado({ ...estado, dataNascimento: validarDataNascimento(formValores.dataNascimento) })}
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Control
                  className="formCampo"
                  required
                  type="text"
                  placeholder="Área de interesse"
                  value={formValores.areaInteresse}
                  onChange={setAreaInteresse}
                  isValid={estado.areaInteresse}
                  isInvalid={estado.areaInteresse != undefined ? !estado.areaInteresse : undefined}
                  onBlur={() => setEstado({ ...estado, areaInteresse: formValores.areaInteresse.length >= 2 })}
                />
              </Form.Group>
              {formValores && <Row className="input-files-ftc-group">
                <Col xs={12} lg={6} className='mb-3'>
                  <label htmlFor="input-files-ftc" >Selecionar arquivo PDF(Máximo 5mb)</label>
                </Col>
                <Col xs={12} lg={6} className='input-span-box mb-3'>
                  <span className={`${estado.files ? 'valid' : estado.files === false ? 'invalid' : ''}`}>
                    {formValores.files.length > 0 ? `${formValores.files.length} ${formValores.files.length > 1 ? 'arquivos selecionados' : 'arquivo selecionado'}` : 'Nenhum arquivo selecionado'}
                  </span>
                </Col>
                <input id='input-files-ftc' type="file" onChange={(e) => {
                    setFormValores({
                      ...formValores,
                      files: FileListToFileArray(e.target.files ?? new FileList())
                    })
                  }}
                  accept='.pdf'
                />
                </Row>}

              <Row>
                <Col lg={12} className="ReCaptcha">
                  {captchaSiteKey &&  <ReCAPTCHA
                    sitekey={captchaSiteKey}
                    onChange={(e: any) => { setCaptcha(e) }}
                    onErrored={() => {
                      setCaptcha(undefined)
                      setEstado({ ...estado, captcha: undefined })
                    }}
                    onExpired={() => {
                      setCaptcha(undefined)
                      setEstado({ ...estado, captcha: undefined })
                    }}
                  />}
                </Col>
                <Col lg={12} className="mt-3 itemBotao">
                  <Button className="botao" onClick={() => enviarForm2()}>
                    Enviar <ArrowRight className="seta" size={30} />
                  </Button>
                </Col>
              </Row>

              {estado.captcha == false ?
                <Row>
                  <Col lg={12}>
                    <Alert className="alert mt-3" variant="secondary">
                      Por favor responda o ReCaptcha !
                    </Alert>
                  </Col>
                </Row> : null}
              {postFormSuccess != PostFormStatus.NULL ?
                <Row>
                  <Col lg={12}>
                    <Alert className="alert mt-3" variant={
                      postFormSuccess === PostFormStatus.ENVIADO ? 'success' : postFormSuccess === PostFormStatus.ENVIANDO ? 'primary' : 'danger'}>
                      {postFormSuccess === PostFormStatus.ENVIADO ? 'Formulário enviado com sucesso !' :
                        postFormSuccess === PostFormStatus.ENVIANDO ? 'Enviando ...' : 'Houve um erro ao enviar o formulário, por favor, tente novamente mais tarde!'}
                    </Alert>
                  </Col>
                </Row> : null
              }
            </Col>

            <Col lg={6} className="boxDireita">
              <img src={img} alt="" className="imgTrabalheConosco" />

              {/* <div className="infoForms">
                <Row>
                  <Col lg={1}>
                    <div className="icone">
                      <Icon.PinMap size={35} />
                    </div>
                  </Col>
                  <Col lg={11} className="itemTexto">
                    <h3>Unidade Industrial I - Matriz</h3>
                    <p>Rodovia RS 240 - km 06 - Caixa Postal 30</p>
                    <p>CEP: 93180-000 - Portão / RS - Brasil </p>
                    <p>Fone: (51) 3562-9500</p>
                  </Col>
                </Row>
                <Row>
                  <Col lg={1}>
                    <div className="icone">
                      <Icon.PinMap size={35} />
                    </div>
                  </Col>
                  <Col lg={11} className="itemTexto">
                    <h3>Unidade Industrial II</h3>
                    <p>BR 101 - km 98 s/nº - Distrito Industrial</p>
                    <p>CEP: 58322-000 - Conde / PB - Brasil </p>
                    <p>Fone: (83) 3234-0559</p>
                  </Col>
                </Row>
              </div> */}
            </Col>
          </Row>
        </Form>
      </Row>
    </Container>
  )
}