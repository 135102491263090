import './secao-servicos.scss'
import ComponenteNaoEncotrado from '../../componente-nao-econtrado/componente-nao-econtrado';
import { Container, Row, Col, Card, Button } from 'react-bootstrap';
import placeHolder3 from '../../../arquivos/imagens/place-holder-3.svg'
import { Link } from 'react-router-dom';
import { ArrowRight, FileEarmarkSpreadsheetFill } from 'react-bootstrap-icons';
import { useEffect, useState } from 'react';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import { COLORS } from '../../../temas/cores';
import { gestor } from '../../../servicos/gestor';
import { FuncoesAuxiliares } from '../../../utilidades/funcoesAuxiliares';
import HTMLReactParser from 'html-react-parser';
import Carousel from 'react-multi-carousel';

export type comportamentoSecaoServicos =
  'home' |
  'servicos' |
  'servico' | null;

type CardServicoType = {
  titulo: string,
  texto: string,
  imagem: string,
  link: string
}

const montaCardsListagem = (cardInfos?: Array<CardServicoType>) => {

  const cardsElement: Array<JSX.Element> = [];

  cardInfos?.forEach((card, index) => {
    cardsElement.push(
      <Card key={index} className="card">
        <Card.Img className="imagemCard" variant="top" src={card.imagem} />
        <Card.Body className="cardBody">
          <Card.Title className="titulo">{card.titulo}</Card.Title>
          <Card.Text className="texto">
            {card.texto}
          </Card.Text>
          <Link to={card.link} className='link'>
            <Button className='botao'>
              Saiba Mais
              <ArrowRight className='seta' size={30} />
            </Button>
          </Link>
        </Card.Body>
      </Card>
    );
  });

  return (
    <div>
      {cardsElement}
    </div>
  )
}

const montaCarousel = (cardInfos?: Array<CardServicoType>) => {

  const cardsElement: Array<JSX.Element> = [];

  cardInfos?.forEach((card, index) => {
    cardsElement.push(
        <Card key={index} className="card">
          <Card.Img className="imagemCard" variant="top" src={card.imagem} />
          <Card.Body className="cardBody">
            <Card.Title className="titulo">{card.titulo}</Card.Title>
            <Card.Text className="texto">
              {card.texto}
            </Card.Text>
            <Link to={card.link} className='link'>
              <Button className='botao'>
                Saiba Mais
                <ArrowRight className='seta' size={30} />
              </Button>
            </Link>
          </Card.Body>
        </Card>
    );
  });

  return cardsElement;

}

export default function SecaoServicos({ comportamento }: { comportamento: comportamentoSecaoServicos }) {

  type SecaoServicos = {
    loading: boolean,
    erroAoCarregar: boolean,
    dados: {
      imagem?:string,
      titulo?: string,
      texto?: string,
      cards?: Array<CardServicoType>
    }
  }

  const [estado, setEstado] = useState<SecaoServicos>({
    loading: true,
    erroAoCarregar: false,
    dados: {
      cards: []
    }
  });

  useEffect(() => {

    gestor.getServicos().then((retornoServicos) => {

      gestor.getCategoriasComFiltro("SERVICO").then((retornoCategorias) => {

        if (retornoCategorias.error) {
          estado.erroAoCarregar = true;
          setEstado({ ...estado });
          return;
        }

        estado.dados.cards = [];

        retornoCategorias.dados?.forEach((categoria) => {

          const servico = retornoServicos.dados?.data?.filter((servico) => servico.category == categoria.title);

          if (!servico?.at(0)) return;

          estado.dados.cards?.push({
            imagem: categoria.image,
            titulo: categoria.title,
            texto: categoria.description,
            link: `servicos/${servico?.at(0)?.id}/${FuncoesAuxiliares.toUrlAmigavelConverter(servico?.at(0)?.title)}`,
          })
        })

        if (!estado.dados.cards.at(0)) estado.erroAoCarregar = true;

        gestor.getSectionsIndex().then(respostaSecaoIndex => {

          const secaoServico = respostaSecaoIndex.filter(secao => secao.section_informations.at(0)?.section_data?.titulo === "SERVIÇOS");

          estado.dados.titulo = secaoServico.at(0)?.section_informations.at(0)?.section_data?.titulo;
          estado.dados.texto = secaoServico.at(0)?.section_informations.at(0)?.section_data?.descricao;
          estado.dados.imagem = secaoServico.at(0)?.section_informations.at(0)?.section_data?.imagensArray?.at(0)?.src??"";
          
          if (!secaoServico) {
            estado.erroAoCarregar = true;
            setEstado({ ...estado });
            return;
          }

          estado.loading = false;
          setEstado({ ...estado });
        })

      });
    });
  }, []);


  if (estado.erroAoCarregar) {
    return null;
  }

  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 1
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  };

  return (
    <section id="SecaoServicos">

      <div className='fundo' />

      <Container>

        {estado.loading ?
          <SkeletonTheme baseColor={COLORS.skeletonBaseColor} highlightColor={COLORS.skeletonHighlightColor}>
            <Row className="containerLoading">
              <Col lg={6}>
                <Skeleton className='imagemLoading' />
              </Col>
              <Col lg={6} className="containerEscritaLoading">
                <Skeleton className='tituloLoading' />
                <Skeleton className='textoLoading' count={4} />
                <Skeleton className='cardLoading' />
                <Skeleton className='cardLoading' />
              </Col>
            </Row>
          </SkeletonTheme>
          :
          <Row>
            <Col lg={6}>
              <img className='imagemPrincipal' src={estado.dados.imagem} alt="servicos" />
            </Col>
            <Col lg={6} className="containerServicos">
              <div className='containerTitulo'>
                <hr className='barra' />
                <h3 className='titulo'>
                  {estado.dados?.titulo}
                </h3>
              </div>
              <div className='texto'>
                {HTMLReactParser(estado.dados.texto ?? "")}
              </div>
              {montaCardsListagem(estado.dados.cards)}
            </Col>
          </Row>}

        {estado.loading ?
          <></>
          :
          <Row>
            <Carousel responsive={responsive}
              className='carousel'
              showDots={true}
              dotListClass="dots"
              removeArrowOnDeviceType={['tablet', 'mobile']}>
              {montaCarousel(estado.dados.cards)}
            </Carousel>
          </Row>}

      </Container>

    </section >
  );
}