import './pagina-credenciados.scss'
import SecaoUnidades from '../../componentes/secoes/secao-unidades/secao-unidades';
import SecaoBanner from '../../componentes/secoes/secao-banner/secao-banner';
import { useTitle } from '../../utilidades/tittle';

export default function PaginaCredenciados() {
  window.scroll(0,0);
  useTitle("Credenciados");
  return (
    <section id="PaginaCredenciados">
      <SecaoBanner comportamento='credenciados' />
      <SecaoUnidades comportamento='credenciados' />
    </section>
  );
}