import './pagina-trabalhe-conosco.scss'
import SecaoFormulario from '../../componentes/secoes/secao-formulario/secao-formulario';
import SecaoBanner from '../../componentes/secoes/secao-banner/secao-banner';
import { useTitle } from '../../utilidades/tittle';

export default function PaginaTrabalheConosco() {
  window.scroll(0,0);
  useTitle("Trabalhe Conosco");
  return (
    <section id="PaginaTrabalheConosco">
      <SecaoBanner comportamento="trabalhe-conosco" />
      <SecaoFormulario comportamento="trabalhe-conosco" />
    </section>
  );
}