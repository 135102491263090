import { IDeserializable } from "./deserializable";
import { PostType } from "./types/type-post";

export default class PostModel implements IDeserializable{
  
  dados?: PostType;
  erro?:boolean;
  
  deserialize(input: any): this {
    Object.assign(this,input);
    return this;
  }
}