import './pagina-quem-somos.scss'
import SecaoBanner from '../../componentes/secoes/secao-banner/secao-banner';
import SecaoQuemSomos from '../../componentes/secoes/secao-quem-somos/secao-quem-somos';
import { useTitle } from '../../utilidades/tittle';

export default function PaginaQuemSomos(){
  window.scroll(0,0);
  useTitle("Institucional");
  return(
    <section id="PaginaQuemSomos">
      <SecaoBanner comportamento='institucional'/>
      <SecaoQuemSomos comportamento='quem-somos'/>
    </section>
  );
}