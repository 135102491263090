import './pagina-home.scss'
import SecaoBanner from '../../componentes/secoes/secao-banner/secao-banner';
import SecaoQuemSomos from '../../componentes/secoes/secao-quem-somos/secao-quem-somos';
import SecaoCategoriasProduto from '../../componentes/secoes/secao-categorias-produto/secao-categorias-produto';
import SecaoServicos from '../../componentes/secoes/secao-servicos/secao-servicos';
import SecaoBlog from '../../componentes/secoes/secao-blog/secao-blog';
import { useTitle } from '../../utilidades/tittle';
import SecaoCredenciados from '../../componentes/secoes/secao-credenciados/secao-credenciados';

export default function PaginaHome(){
  window.scroll(0,0);
  useTitle("Moreflex");
  return(
    <section id="PaginaHome">
      <SecaoBanner comportamento='home' />
      <SecaoCategoriasProduto comportamento='home'/>
      <SecaoServicos comportamento='home'/>
      <SecaoCredenciados comportamento='home'/>
      <SecaoQuemSomos comportamento='home'/>
      <SecaoBlog comportamento='home'/>
    </section>
  );
}