
function formatCaminhoTitulo(caminho : string){
  return caminho.replaceAll('-',' ').replace(/(^\w{1})|(\s+\w{1})/g, (letra:string) => letra.toUpperCase());
}

function verificaStringNumber(string: string){
  return !isNaN(parseInt(string));
}

function getStringFromHtml(text: string): string {
  var res = new DOMParser().parseFromString(text, 'text/html')!.body!.firstChild!.textContent;
  return res ? res : ''
}

function toUrlAmigavelConverter(url?: string): string {
  return url?.toLowerCase()
      .replace(/[áàãâä]/g, 'a')
      .replace(/[éèẽêë]/g, 'e')
      .replace(/[íìîĩï]/g, 'i')
      .replace(/[óòõôö]/g, 'o')
      .replace(/[úùũûü]/g, 'u')
      .replace(/[ç]/g, 'c')
      .replace(/['"!@#$%¨&*()-+:<>=|\\/ºª°?]/g, '')
      .replace(/ /g, '-')??""
      
}

function urlNormalize(caminho:string){
  return caminho.normalize('NFD').replace(/[\u0300-\u036f]/g, "").toLowerCase().replaceAll(" ", "-");
}

export const FuncoesAuxiliares = {
  formatCaminhoTitulo,
  verificaStringNumber,
  getStringFromHtml,
  toUrlAmigavelConverter,
  urlNormalize
};