import "./secao-produto.scss";
import {
  Carousel as CarouselBoot,
  Col,
  Container,
  ListGroup,
  Row,
} from "react-bootstrap";
import placeholder from "../../../arquivos/imagens/placeholder.png";
import iconeRota from "../../../arquivos/icones/roda-de-carro.png";
import iconeRoda from "../../../arquivos/icones/rodovia.png";
import Carousel from "react-multi-carousel";
import { Link, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { gestor } from "../../../servicos/gestor";
import { ProdutoServicoType } from "../../../modelos/types/type-produto";
import { ProdutoCaracteristicaType } from "../../../modelos/types/type-produto-caracteristica";
import { ArquivoType } from "../../../modelos/types/type-arquivo";
import parse from "html-react-parser";
import { toTop } from "../../../utilidades/htmlTools";

import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { COLORS } from "../../../temas/cores";
import { ImagemViewerZoom } from "../../imagem-viewer-zoom/imagem-viewer-zoom";

export type comportamentoSecaoProduto = "produto" | null;
const montaCarrosel = (
  listaImagens: Array<string>,
  mostrarImagensModal: (first: number) => void
) => {
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 1,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  const imagensElement: Array<JSX.Element> = [];

  listaImagens.forEach((imagens, index) => {
    imagensElement.push(
      <img
        key={index}
        src={imagens}
        alt="imagemCarousel"
        className="imagemCarousel"
        onClick={() => mostrarImagensModal(index)}
      />
    );
  });

  return (
    <Carousel
      responsive={responsive}
      className="carousel"
      showDots={false}
      dotListClass="dots"
      removeArrowOnDeviceType={["tablet", "mobile"]}
    >
      {imagensElement}
    </Carousel>
  );
};

enum CaractetisticaEnum {
  EIXO,
  APLICACAO,
}

type SecaoProdutoDado = {
  titulo: string;
  imagemPrincipal: string;
  subImagens: string[];
  caracteristicas: {
    caracteristica: string;
    descricao: string;
    tipo: CaractetisticaEnum;
  }[];
  descricaoHtml: string;
  arquivos: { nome: string; link: string }[];
};

const ProdutoServicoTypeToSecaoProdutoDado = (
  obj: ProdutoServicoType
): SecaoProdutoDado => {
  const spd: SecaoProdutoDado = {
    titulo: obj.title,
    imagemPrincipal: obj.imagensArray.filter((img) => img.showFirst == true)[0]
      .src,
    caracteristicas: obj.caracteristicasArray.map(
      (c: ProdutoCaracteristicaType) => {
        return {
          caracteristica: c.caracteristica,
          descricao: c.descricao,
          tipo:
            c.caracteristica == "Eixos"
              ? CaractetisticaEnum.EIXO
              : CaractetisticaEnum.APLICACAO,
        };
      }
    ),
    subImagens: obj.imagensArray
      .filter((img) => img.showFirst == false)
      .map((img) => img.src),
    arquivos: obj.arquivosArray.map((arq: ArquivoType) => {
      var sections = arq.src.split("/");
      var nome = sections[sections.length - 1];
      return {
        nome,
        link: arq.src,
      };
    }),
    descricaoHtml: obj.description,
  };

  return spd;
};

type EstadoSecaoProduto = {
  carregando: boolean;
  erro: boolean;
};

export default function SecaoProduto({
  comportamento,
}: {
  comportamento: comportamentoSecaoProduto;
}) {
  const [dados, setDados] = useState<SecaoProdutoDado>();
  const [estadoSecaoProduto, setEstadoSecaoProduto] =
    useState<EstadoSecaoProduto>({ carregando: true, erro: false });
  const [imagensModal, setImagensModal] = useState<string[]>([]);

  const { idProduto } = useParams();

  useEffect(() => {
    toTop();
    gestor.getProdutoById(Number(idProduto)).then((response) => {
      if (!response.error) {
        setDados(ProdutoServicoTypeToSecaoProdutoDado(response.dados!));
        setEstadoSecaoProduto({ carregando: false, erro: false });
      } else setEstadoSecaoProduto({ carregando: false, erro: true });
    });
  }, [idProduto]);

  const mostrarImagensNoModal = (first: number) => {
    const imagens = [dados?.imagemPrincipal ?? ""];
    var aux = imagens[0];
    imagens[0] = imagens[first];
    imagens[first] = aux;

    setImagensModal(imagens);
  };

  let nomeCaracteristicas: Array<string> = [];

  dados?.caracteristicas.forEach((caracteristica) => {
    nomeCaracteristicas.push(caracteristica.caracteristica);
  });

  nomeCaracteristicas = nomeCaracteristicas.filter(
    (e, i) => nomeCaracteristicas.indexOf(e) === i
  );

  const concatenados: Array<{ nome: string; descricao: string; tipo: number }> =
    [];

  nomeCaracteristicas.forEach((nome, index) => {
    concatenados[index] = {
      nome: nome,
      descricao: "",
      tipo: nome === "Aplicação" ? 1 : 0,
    };
    dados?.caracteristicas.forEach((caracteristica) => {
      if (caracteristica.caracteristica === nome) {
        concatenados[index].descricao += concatenados[index].descricao
          ? `/ ${caracteristica.descricao} `
          : `${caracteristica.descricao} `;
      }
    });
  });

  return (
    <SkeletonTheme
      baseColor={COLORS.skeletonBaseColor}
      highlightColor={COLORS.skeletonHighlightColor}
    >
      <section id="SecaoProduto">
        {comportamento === "produto" ? (
          <Container>
            <ImagemViewerZoom imagens={imagensModal} />
            {!estadoSecaoProduto.erro && (
              <Row>
                <Col xs={12} lg={4} className="colCarousel">
                  {estadoSecaoProduto.carregando ? (
                    <SkeletonsImagemProduto />
                  ) : (
                    montaCarrosel(
                      [dados!.imagemPrincipal],
                      mostrarImagensNoModal
                    )
                  )}
                </Col>
                <Col xs={12} lg={8} className="colInfo">
                  {estadoSecaoProduto.carregando ? (
                    <SkeletonsTituloProduto />
                  ) : (
                    <h2>{dados?.titulo}</h2>
                  )}
                  {/* <hr /> */}

                  {estadoSecaoProduto.carregando ? (
                    <SkeletonsCaracteristicasProduto />
                  ) : (
                    <>
                      {concatenados?.map((caracteristica, i) => (
                        <div className="prodInfo" key={i}>
                          <img
                            src={
                              caracteristica.tipo == CaractetisticaEnum.EIXO
                                ? iconeRota
                                : iconeRoda
                            }
                          />
                          <div>
                            <p>{caracteristica.nome}:</p>
                            <span>{caracteristica.descricao}</span>
                          </div>
                        </div>
                      ))}

                      <Row className="subImagens mobile">
                        <CarouselBoot>
                          {dados!.subImagens.map((img, i) => (
                            <CarouselBoot.Item>
                              <Col xs={12} lg={4} key={i} className="subImagem">
                                <img src={img} />
                              </Col>
                            </CarouselBoot.Item>
                          ))}
                        </CarouselBoot>
                      </Row>

                      <Row className="subImagens desktop">
                        {dados!.subImagens.map((img, i) => (
                          <Col xs={12} lg={4} key={i} className="subImagem">
                            <img src={img} />
                          </Col>
                        ))}
                      </Row>
                    </>
                  )}
                </Col>

                <Col xs={12} lg={12} className="colCaracteristicas">
                  {estadoSecaoProduto.carregando ? (
                    <SkeletonsInfoProduto />
                  ) : (
                    <>
                      <i className="bi bi-gear" />
                      <div>
                        <p>Características:</p>
                        {parse(dados!.descricaoHtml)}
                      </div>
                    </>
                  )}
                </Col>

                <Col xs={12} lg={12} className="colArq">
                  {estadoSecaoProduto.carregando ? (
                    <SkeletonsArquivoProduto />
                  ) : (
                    <>
                      <h5>Arquivos para baixar</h5>
                      {dados!.arquivos.map((arq, i) => (
                        <a
                          href={arq.link}
                          className="arquivoLink"
                          key={i}
                          target="_blank"
                        >
                          <i className="bi bi-download" />
                          <span>{arq.nome}</span>
                        </a>
                      ))}
                    </>
                  )}
                </Col>
              </Row>
            )}
          </Container>
        ) : null}

        {/* <ComponenteNaoEncotrado escrita='listagem de Produto não econtrado' /> */}
      </section>
    </SkeletonTheme>
  );
}

// ------------------ skeletons ---------------------------

const SkeletonsImagemProduto = () => {
  return (
    <div className="skeletonImagemProduto">
      <Skeleton width={"100%"} height={"100%"} />
    </div>
  );
};

const SkeletonsTituloProduto = () => {
  return (
    <div className="skeletonTituloProduto">
      <Skeleton width={"100%"} height={"100%"} />
    </div>
  );
};

const SkeletonsCaracteristicasProduto = () => {
  return (
    <div className="skeletonCaracteristicasProduto">
      <Skeleton width={"100%"} height={"100%"} />
    </div>
  );
};

const SkeletonsInfoProduto = () => {
  return (
    <div className="skeletonInfoProduto">
      <Skeleton width={"100%"} height={"100%"} />
    </div>
  );
};

const SkeletonsArquivoProduto = () => {
  return (
    <div className="skeletonArquivoProduto">
      <Skeleton width={"100%"} height={"100%"} />
    </div>
  );
};
