import { IDeserializable } from "./deserializable";
import { CategoriaType } from "./types/type-categoria";

export default class BLogCategoriaModel implements IDeserializable{
  
  dados?:Array<CategoriaType>;
  erro ?: boolean;
  
  deserialize(input: any): this {
    Object.assign(this,input);
    return this;
  }
}