import { IDeserializable } from "./deserializable";

export enum ProdutoServicoEnum {
  PRODUTO = 'PRODUTO',
  SERVICO = 'SERVICO'
}

export type CategoriaProduto = {
  id?: number
  title: string
  descriptionHtml: string
  image: string
  category_id?: number | string
  quantity: number
  type: ProdutoServicoEnum
}

export class CategoriasProdutoResponse implements IDeserializable {
    dados: CategoriaProduto[] = []
    error: boolean = false

    deserialize(input: any): this {
        Object.assign(this, input)
        return this
    }
}