import { useEffect, useState } from 'react';
import { Button, Card, Col, Container, Row } from 'react-bootstrap';
import { ArrowRight } from 'react-bootstrap-icons';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import Carousel from 'react-multi-carousel';
import { Link, useLocation } from 'react-router-dom';
import { CategoriaType } from '../../../modelos/types/type-categoria';
import { gestor } from '../../../servicos/gestor';
import { COLORS } from '../../../temas/cores';
import { FuncoesAuxiliares } from '../../../utilidades/funcoesAuxiliares';
import './secao-blog.scss';

export type comportamentoSecaoBlog =
  'home' |
  'blog' |
  'post' | null;

type CardBlogType = {
  img: string,
  categoria: string,
  data: string,
  titulo: string,
  texto: string,
  link: string
}

function montaCarouselBlogs(cards?: Array<CardBlogType>) {
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 3
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  };

  const listaDeCards: Array<JSX.Element> = [];

  cards?.forEach((card, index) => {
    listaDeCards.push(
      <Link key={index} to={card.link} className='link' onClick={() => window.scroll(0, 0)}>
        <Card className="card" >
          <Card.Img className="imagem" variant="top" src={card.img}></Card.Img>
          <Card.ImgOverlay>
            <Card.Title className="categoria">{card.categoria}</Card.Title>
          </Card.ImgOverlay>
          <Card.Header className="data">{card.data}</Card.Header>
          <Card.Body className="cardBody">
            <Card.Title className="titulo">{card.titulo}</Card.Title>
            <Card.Text className="texto">
              {card.texto}
            </Card.Text>
            <Button className='botao'>
              Saiba Mais
              <ArrowRight className='seta' size={30} />
            </Button>
          </Card.Body>
        </Card>
      </Link>
    )
  });

  return (
    <Carousel responsive={responsive}
      className='carousel'
      showDots={true}
      dotListClass="dots"
      removeArrowOnDeviceType={['tablet', 'mobile']}>
      {listaDeCards}
    </Carousel>
  );

}

function montaCard(cards?: Array<CardBlogType>) {

  const retorno: Array<JSX.Element> = [];

  cards?.forEach((card, index) => {

    retorno.push(
      <Col key={index} lg={4}>
        <Link to={card.link} className='link' onClick={() => window.scroll(0, 0)}>
          <Card className="card" >
            <Card.Img className="imagem" variant="top" src={card.img}></Card.Img>
            <Card.ImgOverlay>
              <Card.Title className="categoria">{card.categoria}</Card.Title>
            </Card.ImgOverlay>
            <Card.Header className="data">{card.data}</Card.Header>
            <Card.Body className="cardBody">
              <Card.Title className="titulo">{card.titulo}</Card.Title>
              <Card.Text className="texto">
                {card.texto}
              </Card.Text>
              <Button className='botao'>
                Saiba Mais
                <ArrowRight className='seta' size={30} />
              </Button>
            </Card.Body>
          </Card>
        </Link>
      </Col>
    );

  });

  return retorno;

}

function montaOptionsCategoria(listaDeCateogira?: Array<CategoriaType>) {

  const retorno: Array<JSX.Element> = [];

  listaDeCateogira?.forEach((categoria, index) => {

    retorno.push(<option key={index} value={categoria.id}>{categoria.name}</option>);

  });

  return retorno;

}

export default function SecaoBlog({ comportamento }: { comportamento: comportamentoSecaoBlog }) {

  type SecaoBlog = {
    loading: boolean,
    erroAoCarregar: boolean,
    configuracao: {
      titulo?: boolean,
      texto?: boolean,
      carrosel?: boolean,
      listagemPorPaginas?: boolean
    },
    controle: {
      categoriaAtual?: string,
      totalDePaginas?: number,
      paginaAtual?: number
    },
    dados: {
      titulo?: string,
      texto?: string,
      cards?: Array<CardBlogType>
      listaDeCateogira?: Array<CategoriaType>,
    }
  }

  const [estado, setEstado] = useState<SecaoBlog>({
    loading: true,
    erroAoCarregar: false,
    configuracao: {},
    controle: {},
    dados: {}
  });

  const local = useLocation();

  function mudaCategoria(id: string): void {
    gestor.getBlog('1', '9', id).then((retorno) => {

      if (retorno.erro) {
        estado.erroAoCarregar = true;
        setEstado({ ...estado });
        return;
      }

      estado.controle = {
        categoriaAtual: id,
        paginaAtual: 1,
        totalDePaginas: retorno.dados?.total as number / 9
      }

      estado.dados.cards = [];

      retorno.dados?.data?.forEach(post => {

        if (post.disabled) return;

        const meses = ['janeiro', 'fevereiro', 'março', 'abril', 'maio', 'junho', 'julho', 'agosto', 'setembro', 'outubro', 'novembro', 'dezembro'];

        const data = new Date(post.start_date);

        estado.dados.cards?.push({

          titulo: post.title,
          texto: FuncoesAuxiliares.getStringFromHtml(post.description),
          img: post.imagensArray?.at(0)?.src as string,
          categoria: post.category,
          data: `${data.getDay()} ${meses[data.getMonth()]} ${data.getFullYear()} `,
          link: `/blog/${FuncoesAuxiliares.toUrlAmigavelConverter(post.category)}/${post.id}/${FuncoesAuxiliares.toUrlAmigavelConverter(post.title)}`

        });

      })

      estado.loading = false;
      setEstado({ ...estado });

    });
  }

  function montaBotoesPassaPagina(quantidadeDeBotoes?: number) {

    const retorno: Array<JSX.Element> = [];


    quantidadeDeBotoes = quantidadeDeBotoes ? Math.ceil(quantidadeDeBotoes) : 0;

    if (quantidadeDeBotoes <= 1) return;

    for (let i = 1; i <= quantidadeDeBotoes; i++) {
      retorno.push(
        <button key={i}
          className={`BotoeTrocaPagina ${estado.controle.paginaAtual == i ? 'active' : ''}`}
          value={i}
          onClick={(event: any) => {
            
            window.scroll(0,0);
            estado.controle.paginaAtual = event.target.value;
            atualizaPagina();

          }}>
          {i}
        </button>);
    }

    return retorno;

  }

  function atualizaPagina() {

    gestor.getBlog(`${estado.controle.paginaAtual}`, '9', estado.controle.categoriaAtual).then((retorno) => {
      
      if (retorno.erro) {
        estado.erroAoCarregar = true;
        setEstado({ ...estado });
      }

      estado.dados.cards = [];

      retorno.dados?.data?.forEach(post => {

        if (post.disabled) return;

        const meses = ['janeiro', 'fevereiro', 'março', 'abril', 'maio', 'junho', 'julho', 'agosto', 'setembro', 'outubro', 'novembro', 'dezembro'];

        const data = new Date(post.start_date);

        estado.dados.cards?.push({

          titulo: post.title,
          texto: FuncoesAuxiliares.getStringFromHtml(post.description),
          img: post.imagensArray?.at(0)?.src as string,
          categoria: post.category,
          data: `${data.getDay()} ${meses[data.getMonth()]} ${data.getFullYear()} `,
          link: `/blog/${FuncoesAuxiliares.toUrlAmigavelConverter(post.category)}/${post.id}/${FuncoesAuxiliares.toUrlAmigavelConverter(post.title)}`

        });

      })

      estado.loading = false;
      setEstado({ ...estado });

    })

  }

  useEffect(() => {

    if (comportamento === 'home') {

      estado.configuracao = {
        titulo: true,
        texto: true,
        carrosel: true,
      }

      estado.dados = {
        titulo: "Blog",
        texto: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus eu id lorem risus, parturient amet egestas. Urna, nibh interdum augue cras et proin felis. Faucibus viverra erat neque ac in velit tortor dui. Mauris aliquet malesuada metus adipiscing massa pellentesque nunc, condimentum.",
      };

      gestor.getBlog().then((retorno) => {

        if (retorno.erro) {
          estado.erroAoCarregar = true;
          setEstado({ ...estado });
        }

        estado.dados.cards = [];

        retorno.dados?.data?.forEach(post => {

          if (post.disabled) return;

          const meses = ['janeiro', 'fevereiro', 'março', 'abril', 'maio', 'junho', 'julho', 'agosto', 'setembro', 'outubro', 'novembro', 'dezembro'];

          const data = new Date(post.start_date);

          if (estado.dados.cards?.length as number < 5) {
            estado.dados.cards?.push({
              titulo: post.title,
              texto: FuncoesAuxiliares.getStringFromHtml(post.description),
              img: post.imagensArray?.at(0)?.src as string,
              categoria: post.category,
              data: `${data.getDay()} ${meses[data.getMonth()]} ${data.getFullYear()} `,
              link: `/blog/${FuncoesAuxiliares.toUrlAmigavelConverter(post.category)}/${post.id}/${FuncoesAuxiliares.toUrlAmigavelConverter(post.title)}`
            });
          }

        })

        estado.loading = false;
        setEstado({ ...estado });

      });
    }

    if (comportamento === 'post') {

      estado.configuracao = {
        titulo: true,
        carrosel: true
      }
      estado.dados = {
        titulo: "Relacionados",
      };


      const categoriaAtual = local.pathname.split('/').at(2);

      gestor.getBlogCategorias().then(resposta => {

        if (resposta.erro) {
          estado.erroAoCarregar = true;
          setEstado({ ...estado });
          return;
        }

        let idCategoria;

        resposta.dados?.forEach((categoria) => {

          if (FuncoesAuxiliares.toUrlAmigavelConverter(categoria.name) === categoriaAtual) {
            idCategoria = categoria.id;
            return;
          }

        });

        gestor.getBlog('1', '5', idCategoria).then().then((retorno) => {

          if (retorno.erro) {
            estado.erroAoCarregar = true;
            setEstado({ ...estado });
          }

          estado.dados.cards = [];

          retorno.dados?.data?.forEach(post => {

            if (post.disabled) return;

            const meses = ['janeiro', 'fevereiro', 'março', 'abril', 'maio', 'junho', 'julho', 'agosto', 'setembro', 'outubro', 'novembro', 'dezembro'];

            const data = new Date(post.start_date);

            if (estado.dados.cards?.length as number < 5) {
              estado.dados.cards?.push({
                titulo: post.title,
                texto: FuncoesAuxiliares.getStringFromHtml(post.description),
                img: post.imagensArray?.at(0)?.src as string,
                categoria: post.category,
                data: `${data.getDay()} ${meses[data.getMonth()]} ${data.getFullYear()} `,
                link: `/blog/${FuncoesAuxiliares.toUrlAmigavelConverter(post.category)}/${post.id}/${FuncoesAuxiliares.toUrlAmigavelConverter(post.title)}`
              });
            }

          })

          estado.loading = false;
          setEstado({ ...estado });

        });

      })

    }

    if (comportamento === 'blog') {

      estado.configuracao = {
        listagemPorPaginas: true
      }

      gestor.getBlogCategorias().then(retorno => {

        if (retorno.erro) {
          estado.erroAoCarregar = true;
          setEstado({ ...estado });
        }

        estado.dados.listaDeCateogira = retorno.dados;
        setEstado({ ...estado });

      });

      gestor.getBlog('1', '9').then((retorno) => {

        if (retorno.erro) {
          estado.erroAoCarregar = true;
          setEstado({ ...estado });
        }

        estado.dados.cards = [];

        estado.controle = {
          totalDePaginas: retorno.dados?.total as number / 9,
          paginaAtual: 1
        };

        retorno.dados?.data?.forEach(post => {

          if (post.disabled) return;

          const meses = ['janeiro', 'fevereiro', 'março', 'abril', 'maio', 'junho', 'julho', 'agosto', 'setembro', 'outubro', 'novembro', 'dezembro'];

          const data = new Date(post.start_date);

          estado.dados.cards?.push({

            titulo: post.title,
            texto: FuncoesAuxiliares.getStringFromHtml(post.description),
            img: post.imagensArray?.at(0)?.src as string,
            categoria: post.category,
            data: `${data.getDay()} ${meses[data.getMonth()]} ${data.getFullYear()} `,
            link: `/blog/${FuncoesAuxiliares.toUrlAmigavelConverter(post.category)}/${post.id}/${FuncoesAuxiliares.toUrlAmigavelConverter(post.title)}`

          });

        })

        estado.loading = false;
        setEstado({ ...estado });

      });

    }

  }, []);
  
  if(comportamento == 'home' && !estado.loading && !estado.dados.cards?.at(0)) 
    return null;

  if (estado.erroAoCarregar)
    return null;
  

  return (
    <section id="SecaoBlog">

      {estado.loading ?
        <Container className="containerLoading">
          <SkeletonTheme baseColor={COLORS.skeletonBaseColor} highlightColor={COLORS.skeletonHighlightColor}>

            {comportamento == 'home'?
              <Row>
                <Col>
                  <Skeleton className='tituloLoading' />
                </Col>
              </Row> : null}

            {comportamento == 'home' ?
              <Row>
                <Col>
                  <Skeleton className='textoLoading' count={4} />
                </Col>
              </Row> : null}

            {comportamento == 'blog' ?
              <Row>
                <Col lg={4}>
                  <Skeleton className='selectLoading' />
                </Col>
              </Row>:null}

            {comportamento == 'home' || comportamento == 'blog' ?
              <Row>
                <Col lg={4}>
                  <Skeleton className='cardLoading' />
                </Col>
                <Col lg={4}>
                  <Skeleton className='cardLoading disabilita' />
                </Col>
                <Col lg={4}>
                  <Skeleton className='cardLoading disabilita' />
                </Col>
              </Row> : null}

          </SkeletonTheme>
        </Container>
        :
        <Container>

          {estado.configuracao.titulo ?
            <Row>
              <Col className="containerTitulo">
                <hr className="barra" />
                <h3 className="titulo">{estado.dados?.titulo}</h3>
              </Col>
            </Row> : null}

          {estado.configuracao.texto ?
            <Row>
              <Col lg={7} className="containerEscrita">
                <p className='texto'>
                  {estado.dados?.texto}
                </p>
              </Col>
            </Row> : null}

          {estado.configuracao.carrosel ?
            <Row>
              <Col lg={12}>
                {montaCarouselBlogs(estado.dados?.cards)}
              </Col>
            </Row> : null}

          {estado.configuracao.listagemPorPaginas ?
            <Row>
              <Col lg={4}>
                <select className="select" name="Categoria" defaultValue=""
                  onChange={(event) => {
                    mudaCategoria(event.target.value);
                  }}>
                  <option value="" hidden>Categoria</option>
                  {montaOptionsCategoria(estado.dados.listaDeCateogira)}
                </select>
              </Col>
            </Row> : null}

          {estado.configuracao.listagemPorPaginas ?
            <Row>
              {montaCard(estado.dados?.cards)}
            </Row> : null}

          {estado.configuracao.listagemPorPaginas ?
            <Row >
              <Col className='containerBotoesTrocaPagina' lg={12}>
                {montaBotoesPassaPagina(estado.controle.totalDePaginas)}
              </Col>
            </Row> : null}

        </Container>}

    </section>
  );
}