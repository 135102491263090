import './pagina-produtos.scss'
import { Outlet } from 'react-router-dom';
import SecaoBanner from '../../componentes/secoes/secao-banner/secao-banner';
import { useTitle } from '../../utilidades/tittle';

export default function PaginaProdutos(){
  window.scroll(0,0);
  useTitle("Produtos");
  return (
    <section id="PaginaProdutos">
      <SecaoBanner comportamento='produtos'/>
      <Outlet/>
    </section>
  );
}