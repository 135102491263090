import { IDeserializable } from "./deserializable"

export class GestorResponse<T> {

  constructor(error: boolean, dados?: T){
    this.dados = dados
    this.error = error
  }

  dados?: T
  error: boolean

}