import './pagina-nossas-unidades.scss'
import SecaoUnidades from '../../componentes/secoes/secao-unidades/secao-unidades';
import SecaoBanner from '../../componentes/secoes/secao-banner/secao-banner';
import { useTitle } from '../../utilidades/tittle';

export default function PaginaNossasUnidades() {
  useTitle("Nossa Unidade");
  window.scroll(0, 0);
  return (
    <section id="PaginaNossasUnidades">
      <SecaoBanner comportamento="nossa-unidade" />
      <SecaoUnidades comportamento="nossas-unidades" />
    </section>
  );
}