import './pagina-contato.scss'
import SessaoMaps from '../../componentes/secoes/sessao-maps/sessao-maps';
import SecaoFormulario from '../../componentes/secoes/secao-formulario/secao-formulario';
import SecaoBanner from '../../componentes/secoes/secao-banner/secao-banner';
import { useTitle } from '../../utilidades/tittle';

export default function PaginaContato(){
  window.scroll(0,0);
  useTitle("Fale Conosco");
  return (
    <section id="PaginaContato">
      <SecaoBanner comportamento='contato' />
      <SecaoFormulario comportamento='fale-conosco' />
      <SessaoMaps />
    </section>  
  );
}