import { IDeserializable } from './deserializable';
import { Image } from './image';
export class QuemSomosClass implements IDeserializable{
    id?: string;
    title?: string;
    descriptionAsHtml?: string;
    imagens?: Image[];
    youtubeLink?: string;

    deserialize(input: any): this {
        Object.assign(this, input);
        return this;
    }
}
