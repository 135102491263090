import { useEffect, useState } from 'react';
import { Row, Col, Card } from 'react-bootstrap';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import { Link, useLocation } from 'react-router-dom';
import { CategoriaType } from '../../../modelos/types/type-categoria';
import { PostType } from '../../../modelos/types/type-post';
import { gestor } from '../../../servicos/gestor';
import { COLORS } from '../../../temas/cores';
import { FuncoesAuxiliares } from '../../../utilidades/funcoesAuxiliares';
import './secao-post-relacionados.scss'

export type ComportamentoSecaoPost = 'post';

function montaCards(posts?: Array<PostType>) {

  const retorno: Array<JSX.Element> = [];

  posts?.forEach((post, index) => {

    const data = new Date(post?.start_date ?? "");
    const meses = ['janeiro', 'fevereiro', 'março', 'abril', 'maio', 'junho', 'julho', 'agosto', 'setembro', 'outubro', 'novembro', 'dezembro'];

    retorno.push(
      <Link key={index} reloadDocument
        to={`/blog/${FuncoesAuxiliares.toUrlAmigavelConverter(post.category)}/${post.id}/${FuncoesAuxiliares.toUrlAmigavelConverter(post.title)}`} className='link'>
        <Card className="card">
          <Card.Img className="imagemCard" variant="top" src={post.imagensArray.at(0)?.src} />
          <Card.Body className="cardBody">
            <Card.Title className="titulo">{post.title}</Card.Title>
            <Card.Text className="data">
              {`${data.getDay()} ${meses[data.getMonth()]} ${data.getFullYear()} `}
            </Card.Text>
          </Card.Body>
        </Card>
      </Link>
    );
  });

  return retorno;

}

function montaOptionsCategoria(listaDeCateogira?: Array<CategoriaType>, firstId?: number | string) {

  const retorno: Array<JSX.Element> = [];

  const primeiroId: Array<JSX.Element> = [];

  listaDeCateogira?.forEach((categoria, index) => {

    if (categoria.id == firstId) {
      primeiroId.push(<option key={index} value={categoria.id}>{categoria.name}</option>);
      return;
    }

    retorno.push(<option key={index} value={categoria.id}>{categoria.name}</option>);

  });

  return primeiroId.concat(retorno);

}

export default function SecaoPostRelacionados({ comportamento }: { comportamento: ComportamentoSecaoPost }) {

  type SecaoPostRelacionadosType = {
    loading: boolean,
    erroAoCarregar: boolean,
    controle: {
      totalPost?: number
      categoriaAtual?: number | string
    }
    dados: {
      categorias?: Array<CategoriaType>,
      posts?: Array<PostType>
    }
  }

  const [estado, setEstado] = useState<SecaoPostRelacionadosType>({
    loading: true,
    erroAoCarregar: false,
    controle: {},
    dados: {}
  });

  const local = useLocation();
  const postAtual = local.pathname.split('/').at(3);
  const categoriaAtual = local.pathname.split('/').at(2);

  function mudaCategoria(id: string): void {

    gestor.getBlog('1', estado.controle.totalPost, id).then((retorno) => {

      if (retorno.erro) {
        estado.erroAoCarregar = true;
        setEstado({ ...estado });
        return;
      }

      estado.controle.categoriaAtual = id;

      estado.dados.posts = [];

      retorno.dados?.data?.forEach(post => {

        if (post.disabled) return;
        if(postAtual === `${post.id}`) return;

        estado.dados?.posts?.push(post);

      });

      estado.dados.posts = estado.dados.posts.slice(0,4);

      setEstado({ ...estado });
    });
  }

  useEffect(() => {

    if (comportamento === 'post') {

      gestor.getBlogCategorias().then((retorno) => {

        if (retorno.erro) {
          estado.erroAoCarregar = true;
          setEstado({ ...estado });
          return;
        }

        retorno.dados?.forEach((categoria) => {

          if (FuncoesAuxiliares.toUrlAmigavelConverter(categoria.name) == categoriaAtual) {
            estado.controle.categoriaAtual = categoria.id;
          }

        });

        estado.dados.categorias = retorno.dados;

        estado.controle.totalPost = 10;

        gestor.getBlog(1, estado.controle.totalPost, estado.controle.categoriaAtual).then((retorno) => {

          if (retorno.erro) {
            estado.erroAoCarregar = true;
            setEstado({ ...estado });
            return;
          }

          estado.dados.posts = [];

          retorno.dados?.data?.forEach((post) =>{

            if(post.disabled) return;
            if(postAtual === `${post.id}`) return;
            
            estado.dados.posts?.push(post);
            
          });

          estado.dados.posts = estado.dados.posts.slice(0,4);

          estado.loading = false;
          setEstado({ ...estado });

        })
      });
    }
  }, []);

  return (
    <section id="SecaoPostRelacionados">

      {estado.loading ?
        <SkeletonTheme baseColor={COLORS.skeletonBaseColor} highlightColor={COLORS.skeletonHighlightColor}>
          <Row className='containerLoading'>
            <Col lg={12}>
              <Skeleton className='selectLoading'/>
            </Col>
              <Col lg={12}>
            <div className="containerListagemLoading">
                <Skeleton className='cardLoading'/>
                <Skeleton className='cardLoading'/>
                <Skeleton className='cardLoading'/>
                <Skeleton className='cardLoading'/>
            </div>
              </Col>
          </Row>
        </SkeletonTheme>
        :
        <Row>
          <Col lg={12} className="containerSelect">
            <select className="select"
              onChange={(event) => {
                mudaCategoria(event.target.value);
              }}>lg={12}
              {montaOptionsCategoria(estado.dados.categorias, estado.controle.categoriaAtual)}
            </select>
          </Col>
          <Col lg={12}>
            <div className="containerListagem">
              {montaCards(estado.dados.posts)}
            </div>
          </Col>
        </Row>}

    </section>
  )
}

