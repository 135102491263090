import { Button, Card, Col, Container, Row } from 'react-bootstrap';
import { ArrowRight } from 'react-bootstrap-icons';
import Carousel from 'react-multi-carousel';
import { Link, useLocation } from 'react-router-dom';
import './secao-produtos-relacionados.scss'
import placeholder from '../../../arquivos/imagens/placeholder.png'
import { useEffect, useState } from 'react';
import { gestor } from '../../../servicos/gestor';
import { Produto } from '../../../modelos/produto';
import { FuncoesAuxiliares } from '../../../utilidades/funcoesAuxiliares';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import { COLORS } from '../../../temas/cores';

export type ComportamentosProdutosRelacionados = 'produto';

function montaCarousel(cards?: Array<Produto>, categoria?: { nome?: string, id?: string }) {
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 4
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  };

  const listaDeCards: Array<JSX.Element> = [];

  cards?.forEach((card, index) => {
    listaDeCards.push(
      <Link
        key={index}
        to={`/produtos/${categoria?.id}/${categoria?.nome}/${card.id}/${FuncoesAuxiliares.toUrlAmigavelConverter(card.titulo)}`}
        className='link'
        title={card.titulo}
        reloadDocument>
        <Card className="card" >
          <Card.Img className="imagem" variant="top" src={card.imagens.at(0)?.src}></Card.Img>
          <Card.Body className="cardBody">
            <Card.Title className="titulo">{card.titulo}</Card.Title>
            <Button className='botao'>
              Saiba Mais
              <ArrowRight className='seta' size={30} />
            </Button>
          </Card.Body>
        </Card>
      </Link>
    )
  });

  if (!cards) return null;

  return (
    <Carousel responsive={responsive}
      className='carousel'
      showDots={true}
      dotListClass={"dots" + ` dot${cards?.length}`}
      removeArrowOnDeviceType={['tablet', 'mobile']}>
      {listaDeCards}
    </Carousel>
  );

}

export default function SecaoProdutosRelacionados({ comportamento }: { comportamento: ComportamentosProdutosRelacionados }) {

  type SecaoProdutosRelacionadosType = {
    loading: boolean,
    erroAoCarregar: boolean,
    dados: {
      produtos?: Array<Produto>,
      produtosParecidos: Array<Array<Produto>>
    }
  }

  const [estado, setEstado] = useState<SecaoProdutosRelacionadosType>({
    loading: true,
    erroAoCarregar: false,
    dados: {
      produtos: [],
      produtosParecidos: []
    }
  });

  const caminho = useLocation();
  const idProduto = caminho.pathname.split('/').at(4);
  const idCategoria = caminho.pathname.split('/').at(2);
  const nomeCategoria = caminho.pathname.split('/').at(3);

  useEffect(() => {

    gestor.getProdutoById(Number(idProduto)).then((respostaProduto) => {

      if (respostaProduto.error) {
        estado.erroAoCarregar = true;
        setEstado({ ...estado });
        return;
      }

      const filtros = respostaProduto.dados?.caracteristicasArray;

      const filtrosId = filtros?.map(categoria => categoria?.idCaracteristica as number) ?? [];

      gestor.getProdutosFiltradosPorCaracteristicas(1, 8, Number(idCategoria), filtrosId).then((respostaPodutosFiltrados) => {

        estado.dados.produtos = [];

        respostaPodutosFiltrados.dados?.resultados?.forEach(produto => {

          if (produto.id != idProduto) estado.dados?.produtos?.push(produto);

        });

        if(estado.dados.produtos.length > 0)estado.loading = false;

        filtrosId?.forEach((categoriaFiltroid, index) => {
          
          gestor.getProdutosFiltradosPorCaracteristicas(1, 8, Number(idCategoria), [categoriaFiltroid]).then((respostaPodutosFiltradosUnitario) => {
            
            estado.dados.produtosParecidos[index] = [];
            
            respostaPodutosFiltradosUnitario.dados?.resultados?.forEach(produto => {
              
              let adicionar = true;
              
              estado.dados.produtos?.forEach(produtoPrimeiraGet=>{
                if(produtoPrimeiraGet.id === produto.id) adicionar = false;
              })

              if (produto.id != idProduto && adicionar) estado.dados.produtosParecidos[index].push(produto);
            
            });
            
            if(estado.dados.produtosParecidos[index].length > 0)estado.loading = false;
            setEstado({ ...estado });
          });
        });
        
        setEstado({ ...estado });
      });
    })
  }, []);

  if (estado.erroAoCarregar) {
    return null;
  }

  const totalProdutos: Array<Produto> = [];

  estado.dados.produtos?.forEach(produto=>{
    totalProdutos.push(produto);  
  })

  estado.dados.produtosParecidos.forEach((lista) => {
    lista.forEach((produto) => {
      totalProdutos.push(produto);
    })
  })

  return (
    <section id="SecaoProdutosRelacionados">

      {estado.loading ?
        <Container className='containerLoading'>
          <SkeletonTheme baseColor={COLORS.skeletonBaseColor} highlightColor={COLORS.skeletonHighlightColor}>
            <Row>
              <Col lg={12}>
              <Skeleton className='tituloLoading'/>
              </Col>
            </Row>
            <Row>
              <Col lg={4}>
                <Skeleton className='cardLoading' />
              </Col>
              <Col lg={4}>
                <Skeleton className='cardLoading' />
              </Col>
              <Col lg={4}>
                <Skeleton className='cardLoading' />
              </Col>
            </Row>
          </SkeletonTheme>
        </Container>
        :
        <Container>
          <Row>
            <Col lg={12} className="containerTitulo">
              <hr className="barra" />
              <h3 className="titulo">
                Relacionados
              </h3>
            </Col>
          </Row>
          <Row>
            <Col lg={12} className="containerCarousel">
              {montaCarousel(totalProdutos.splice(0, 5), { nome: nomeCategoria, id: idCategoria })}
            </Col>
          </Row>
        </Container>
      }

    </section>
  )

}