import './secao-listagem-servicos.scss'
import { Container, Row, Col, Button, Card } from 'react-bootstrap';
import { ArrowRight } from 'react-bootstrap-icons';
import { Link } from 'react-router-dom';
import placeHolder2 from '../../../arquivos/imagens/place-holder-2.svg'
import { useEffect, useState } from 'react';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import { COLORS } from '../../../temas/cores';
import { gestor } from '../../../servicos/gestor';
import HTMLReactParser from 'html-react-parser';
import { FuncoesAuxiliares } from '../../../utilidades/funcoesAuxiliares';

type comportamentoSecaoListagemServicos =
  'servicos' |
  'servico';

type CardListagemServicosType = {
  imagem: string,
  titulo: string,
  escrita: string,
  link: string
}

const montaCardsListagem = (cardInfos: Array<CardListagemServicosType>) => {
  const cardsElement: Array<JSX.Element> = [];

  cardInfos.forEach((card, index) => {
    cardsElement.push(
      <Col key={index} lg={4}>
        <Card className="card">
          <Card.Img className="imagem" variant="top" src={card.imagem} />
          <Card.Body className="cardBody">
            <Card.Title className="titulo">{card.titulo}</Card.Title>
            <Card.Text className="texto">
              {card.escrita}
            </Card.Text>
            <Link to={card.link} className='link' onClick={() => window.scroll(0, 0)}>
              <Button className='botao'>
                Saiba Mais
                <ArrowRight className='seta' size={30} />
              </Button>
            </Link>
          </Card.Body>
        </Card>
      </Col>
    );
  });

  return (
    <Row className="containerListagemCards">
      {cardsElement}
    </Row>
  )
}

export default function SecaoListagemServicos({ comportamento }: { comportamento: comportamentoSecaoListagemServicos }) {

  type SecaoListagemServicos = {
    loading: boolean,
    erroAoCarregar: boolean,
    dados: {
      titulo?: string,
      texto?: string,
      cards?: Array<CardListagemServicosType>
    }
  }

  const [estado, setEstado] = useState<SecaoListagemServicos>({
    loading: true,
    erroAoCarregar: false,
    dados: {
      cards: []
    }
  });

  useEffect(() => {

    gestor.getServicos().then((retornoServicos) => {

      gestor.getCategoriasComFiltro("SERVICO").then((retornoCategorias) => {

        if (retornoCategorias.error) {
          estado.erroAoCarregar = true;
          setEstado({ ...estado });
          return;
        }

        estado.dados.cards = [];

        retornoCategorias.dados?.forEach((categoria) => {

          const servico = retornoServicos.dados?.data?.filter((servico) => servico.category == categoria.title);

          if (!servico?.at(0)) return;

          estado.dados.cards?.push({
            imagem: categoria.image,
            titulo: categoria.title,
            escrita: categoria.description,
            link: `/servicos/${servico?.at(0)?.id}/${FuncoesAuxiliares.toUrlAmigavelConverter(servico?.at(0)?.title)}`,
          })
        })
        gestor.getSectionsIndex().then(respostaSecaoIndex => {

          const secaoServico = respostaSecaoIndex.filter(secao => secao.section_informations.at(0)?.section_data?.titulo === "SERVIÇOS");

          estado.dados.titulo = secaoServico.at(0)?.section_informations.at(0)?.section_data?.titulo;
          estado.dados.texto = secaoServico.at(0)?.section_informations.at(0)?.section_data?.descricao;

          if (!secaoServico) {
            estado.erroAoCarregar = true;
            setEstado({ ...estado });
            return;
          }

          estado.loading = false;
          setEstado({ ...estado });

        });
      });
    });
  }, []);

  if (estado.erroAoCarregar) {
    return null;
  }

  return (
    <section id='SecaoListagemServicos'>

      <div className='fundo' />

      {estado.loading ?
        <Container className="containerLoading">
          <SkeletonTheme baseColor={COLORS.skeletonBaseColor} highlightColor={COLORS.skeletonHighlightColor}>
            <Row>
              <Col lg={12}>
                <Skeleton className="loadingTitulo" />
              </Col>
            </Row>
            <Row>
              <Col lg={4}>
                <Skeleton className='loadingCard' />
              </Col>
              <Col lg={4}>
                <Skeleton className='loadingCard' />
              </Col>
              <Col lg={4}>
                <Skeleton className='loadingCard' />
              </Col>
            </Row>
          </SkeletonTheme>
        </Container>
        :
        <Container>
          <Row className="containerEscrita">
            <Col lg={3} className="containerTitulo">
              <hr className='barra' />
              <h3 className='titulo'>
                {estado.dados.titulo}
              </h3>
            </Col>
            <Col lg={5}>
              <div className='texto'>
                {HTMLReactParser(estado.dados.texto ?? "")}
              </div>
            </Col>
          </Row>
          {montaCardsListagem(estado.dados?.cards as Array<CardListagemServicosType>)}
        </Container>}

    </section>
  )

}
