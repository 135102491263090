import { IDeserializable } from "./deserializable";
import { ServicoType } from "./types/type-servico";

export default class ServicosModel implements IDeserializable {

  dados?:{
    data?:Array<ServicoType>,
    total?:number
  }
  
  erro?:boolean;

  deserialize(input: any): this {
    Object.assign(this, input);
    return this;
  }
}