import "./secao-categorias-produto.scss";
import ComponenteNaoEncotrado from "../../componente-nao-econtrado/componente-nao-econtrado";
import { Button, Card, Col, Container, Row } from "react-bootstrap";
import Carousel from "react-multi-carousel";
import placeHolder2 from "../../../arquivos/imagens/place-holder-2.svg";
import { Link } from "react-router-dom";
import { ArrowRight } from "react-bootstrap-icons";
import { useEffect, useState } from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { COLORS } from "../../../temas/cores";
import { gestor } from "../../../servicos/gestor";
import parse from "html-react-parser";
import { converterParaUrlAmigavel } from "../../../utilidades/htmlTools";
import {
  CategoriaProduto,
  ProdutoServicoEnum,
} from "../../../modelos/categorias-produto";
export type comportamentoSecaoCategoriasProduto = "home" | "produtos" | null;

type CardListagemCategoriasProdutos = {
  imagem: string;
  tiulo: string;
  escrita: string;
  link: string;
};

const montaCarrosel = (cardInfos: Array<CardListagemCategoriasProdutos>) => {
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 3,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  const cardsElement: Array<JSX.Element> = [];

  cardInfos.forEach((card, index) => {
    cardsElement.push(
      <Card key={index} className="card">
        <Card.Img className="imagem" variant="top" src={card.imagem} />
        <Card.Body className="cardBody">
          <Card.Title className="titulo">{card.tiulo}</Card.Title>
          <Card.Text className="texto">{parse(card.escrita)}</Card.Text>
          <Link to={card.link} className="link">
            <Button className="botao">
              Saiba Mais
              <ArrowRight className="seta" size={30} />
            </Button>
          </Link>
        </Card.Body>
      </Card>
    );
  });

  return (
    <Row>
      <Col lg={12}>
        <Carousel
          responsive={responsive}
          className="carousel"
          showDots={true}
          dotListClass={`dots dot${cardsElement.length}`}
          removeArrowOnDeviceType={["tablet", "mobile"]}
        >
          {cardsElement}
        </Carousel>
      </Col>
    </Row>
  );
};

const montaCardsListagem = (
  cardInfos: Array<CardListagemCategoriasProdutos>
) => {
  const cardsElement: Array<JSX.Element> = [];

  cardInfos.forEach((card, index) => {
    cardsElement.push(
      <Col key={index} lg={4}>
        <Card className="card">
          <Card.Img className="imagem" variant="top" src={card.imagem} />
          <Card.Body className="cardBody">
            <Card.Title className="titulo">{card.tiulo}</Card.Title>
            <Card.Text className="texto">{card.escrita}</Card.Text>
            <Link to={card.link} className="link">
              <Button className="botao">
                Saiba Mais
                <ArrowRight className="seta" size={30} />
              </Button>
            </Link>
          </Card.Body>
        </Card>
      </Col>
    );
  });

  return <Row className="containerListagemCards">{cardsElement}</Row>;
};

export default function SecaoCategoriasProduto({
  comportamento,
}: {
  comportamento: comportamentoSecaoCategoriasProduto;
}) {
  type SecaoCategoriasProdutoType = {
    loading: boolean;
    erroAoCarregar: boolean;
    dados: {
      titulo?: string;
      texto?: string;
      cards?: Array<CardListagemCategoriasProdutos>;
    };
  };

  const [estado, setEstado] = useState<SecaoCategoriasProdutoType>({
    loading: true,
    erroAoCarregar: false,
    dados: {
      titulo: "",
      texto: "",
      cards: [],
    },
  });

  useEffect(() => {
    gestor.getSectionsIndex().then((response) => {
      const section = response[0].section_informations[0].section_data;

      if (section) {
        var dados = {
          titulo: section.titulo ? section.titulo : "",
          texto: section.descricao ? section.descricao : "",
          cards: estado.dados.cards,
        };

        gestor.getCategoriasProdutoServico().then((responseCat) => {
          var catProdutosOnly: CategoriaProduto[] = responseCat.dados.filter(
            (cat) => cat.type === ProdutoServicoEnum.PRODUTO
          );
          if (!responseCat.error) {
            dados.cards = catProdutosOnly.map((cat) => {
              return {
                imagem: cat.image,
                tiulo: cat.title,
                escrita: cat.descriptionHtml,
                link: `/produtos/${cat.id}/${converterParaUrlAmigavel(
                  cat.title
                )}`,
              };
            });

            setEstado({ loading: false, erroAoCarregar: false, dados });
          } else setEstado({ dados: {}, loading: false, erroAoCarregar: true });
        });
      } else setEstado({ dados: {}, loading: false, erroAoCarregar: true });
    });
  }, []);

  if (estado.erroAoCarregar) {
    return null;
  }

  return (
    <section id="SecaoCategoriasProduto">
      <div className="fundo" />

      {estado.loading ? (
        <Container className="containerLoading">
          <SkeletonTheme
            baseColor={COLORS.skeletonBaseColor}
            highlightColor={COLORS.skeletonHighlightColor}
          >
            <Row>
              <Col lg={12}>
                <Skeleton className="loadingTitulo" />
              </Col>
            </Row>
            <Row>
              <Col lg={4}>
                <Skeleton className="loadingCard" />
              </Col>
              <Col lg={4}>
                <Skeleton className="loadingCard disabilita" />
              </Col>
              <Col lg={4}>
                <Skeleton className="loadingCard disabilita" />
              </Col>
            </Row>
          </SkeletonTheme>
        </Container>
      ) : (
        <Container>
          <Row>
            <Col lg={2} className="containerTitulo">
              <hr className="barra" />
              <h3 className="titulo">{estado.dados.titulo}</h3>
            </Col>
            <Col lg={10} className="containerTitulo">
              <div className="escrita">
                {parse(estado.dados.texto ? estado.dados.texto : "")}
              </div>
            </Col>
          </Row>

          {comportamento === "home"
            ? montaCarrosel(
                estado.dados?.cards as Array<CardListagemCategoriasProdutos>
              )
            : null}

          {comportamento === "produtos"
            ? montaCardsListagem(
                estado.dados?.cards as Array<CardListagemCategoriasProdutos>
              )
            : null}
        </Container>
      )}
    </section>
  );
}
