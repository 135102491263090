import "./secao-quem-somos.scss";
import { Button, Col, Container, ListGroup, Row, Tab } from "react-bootstrap";
import placeHolder from "../../../arquivos/imagens/place-holder.svg";
import { ArrowRight } from "react-bootstrap-icons";
import { Link } from "react-router-dom";
import alvo from "../../../arquivos/icones/alvo.svg";
import certificado from "../../../arquivos/icones/certificado.svg";
import Carousel from "react-multi-carousel";
import placeHolder2 from "../../../arquivos/imagens/place-holder-2.svg";
import { useEffect, useState } from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { COLORS } from "../../../temas/cores";
import { QuemSomosClass } from "../../../modelos/quem-somos";
import { gestor } from "../../../servicos/gestor";
import parse from "html-react-parser";
import { Image } from "./../../../modelos/image";

import {
  convertHtmlNodesToString,
  convertStringHtmlToReactHtml,
  getChildNodesFromHtlmAsString,
} from "../../../utilidades/htmlTools";
import { SectionData } from "../../../modelos/sections";

export type comportamentoSecaoQuemSomos = "home" | "quem-somos" | null;

const montaCarrosel = (listaImagens: Array<string>) => {
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 1,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  const imagensElement: Array<JSX.Element> = [];

  listaImagens.forEach((imagens, index) => {
    imagensElement.push(
      <img
        key={index}
        src={imagens}
        alt="imagemCarousel"
        className="imagemCarousel"
      />
    );
  });

  return (
    <Carousel
      responsive={responsive}
      className="carousel"
      showDots={true}
      dotListClass="dots"
      removeArrowOnDeviceType={["tablet", "mobile"]}
    >
      {imagensElement}
    </Carousel>
  );
};

export default function SecaoQuemSomos({
  comportamento,
}: {
  comportamento: comportamentoSecaoQuemSomos;
}) {
  type SecaoQuemSomos = {
    loading: boolean;
    erro: boolean;
    valores: {
      posicionamento?: boolean;
      meioAmbiente?: boolean;
      comunidade?: boolean;
    };
  };

  type Dado = {
    titulo: string;
    textoHtml: string;
    imagens: string[];
  };

  type Dados = {
    quemSomos?: QuemSomosClass;
    missao?: Dado;
    politica?: Dado;
    posicionamento?: Dado;
    meioAmbiente?: Dado;
    comunidade?: Dado;
  };

  const [estado, setEstado] = useState<SecaoQuemSomos>({
    loading: true,
    erro: false,
    valores: {
      posicionamento: true,
    },
  });

  const [quemSomos, setQuemSomos] = useState<QuemSomosClass>();
  const [dados, setDados] = useState<Dados>({});

  useEffect(() => {
    gestor.getQuemSomos().then((response) => {
      if (response) {
        var quemSomos: QuemSomosClass = response;
        gestor.getSections("INSTITUCIONAL").then((response) => {
          if (response.length) {
            var politicaQualidadeMissao = response[0].section_informations;
            var posicionamentoMeioAmbienteComunidade =
              response[1].section_informations;

            if (politicaQualidadeMissao.length >= 1) {
              var missao: Dado = {
                titulo: politicaQualidadeMissao[0].section_data?.titulo ?? "",
                textoHtml:
                  politicaQualidadeMissao[0].section_data?.descricao ?? "",
                imagens:
                  politicaQualidadeMissao[0].section_data?.imagensArray?.map(
                    (i) => i.src ?? ""
                  ) ?? [],
              };

              var politicaQualidade: Dado = {
                titulo: politicaQualidadeMissao[1].section_data?.titulo ?? "",
                textoHtml:
                  politicaQualidadeMissao[1].section_data?.descricao ?? "",
                imagens:
                  politicaQualidadeMissao[1].section_data?.imagensArray?.map(
                    (i) => i.src ?? ""
                  ) ?? [],
              };

              var posicionamento: Dado = {
                titulo:
                  posicionamentoMeioAmbienteComunidade[0].section_data
                    ?.titulo ?? "",
                textoHtml:
                  posicionamentoMeioAmbienteComunidade[0].section_data
                    ?.descricao ?? "",
                imagens:
                  posicionamentoMeioAmbienteComunidade[0].section_data?.imagensArray?.map(
                    (i) => i.src ?? ""
                  ) ?? [],
              };

              var meioAmbiente: Dado = {
                titulo:
                  posicionamentoMeioAmbienteComunidade[1].section_data
                    ?.titulo ?? "",
                textoHtml:
                  posicionamentoMeioAmbienteComunidade[1].section_data
                    ?.descricao ?? "",
                imagens:
                  posicionamentoMeioAmbienteComunidade[1].section_data?.imagensArray?.map(
                    (i) => i.src ?? ""
                  ) ?? [],
              };

              var comunidade: Dado = {
                titulo:
                  posicionamentoMeioAmbienteComunidade[2].section_data
                    ?.titulo ?? "",
                textoHtml:
                  posicionamentoMeioAmbienteComunidade[2].section_data
                    ?.descricao ?? "",
                imagens:
                  posicionamentoMeioAmbienteComunidade[2].section_data?.imagensArray?.map(
                    (i) => i.src ?? ""
                  ) ?? [],
              };

              setDados({
                ...dados,
                missao,
                politica: politicaQualidade,
                posicionamento,
                meioAmbiente,
                comunidade,
                quemSomos,
              });
              setEstado({ ...estado, loading: false });
            } else setEstado({ ...estado, loading: false, erro: true });
          }
        });
      }
    });
  }, []);

  return (
    (!estado.erro && (
      <section id="SecaoQuemSomos">
        <Container>
          <Row className="reverse">
            {estado.loading ? (
              <SkeletonTheme
                baseColor={COLORS.skeletonBaseColor}
                highlightColor={COLORS.skeletonHighlightColor}
              >
                <Col lg={6}>
                  <Skeleton className="loadingEscrita" />
                </Col>
              </SkeletonTheme>
            ) : (
              <Col lg={6} className="containerEscrita">
                {comportamento === "home" ? (
                  <div>
                    <div className="tituloSecundario">
                      <hr className="barra" />
                      <h3>{dados.quemSomos?.title}</h3>
                    </div>
                    {getResumo(dados.quemSomos?.descriptionAsHtml ?? "")}
                    <Link to="/institucional" className="link">
                      <Button className="botao">
                        Saiba Mais
                        <ArrowRight className="seta" size={30} />
                      </Button>
                    </Link>
                  </div>
                ) : null}

                {comportamento === "quem-somos" ? (
                  <div>{parse(dados.quemSomos?.descriptionAsHtml ?? "")}</div>
                ) : null}
              </Col>
            )}

            {estado.loading || !dados.quemSomos?.imagens?.length ? (
              <SkeletonTheme
                baseColor={COLORS.skeletonBaseColor}
                highlightColor={COLORS.skeletonHighlightColor}
              >
                <Col lg={6}>
                  <Skeleton className="loadingImagem" />
                </Col>
              </SkeletonTheme>
            ) : (
              <Col lg={6} className="colunaImagem">
                <div
                  className={`containerImagem ${
                    comportamento == "home" ? "homeImg" : ""
                  }`}
                >
                  <img
                    src={dados.quemSomos?.imagens![0].src ?? placeHolder}
                    alt="Quem somos"
                    className="imagem"
                  />
                  <div className="sombra"></div>
                </div>
              </Col>
            )}
          </Row>

          {comportamento === "quem-somos" ? (
            <div>
              {estado.loading ? (
                <SkeletonTheme
                  baseColor={COLORS.skeletonBaseColor}
                  highlightColor={COLORS.skeletonHighlightColor}
                >
                  <Row>
                    <Col lg={12}>
                      <Skeleton className="loadingValores" count={4} />
                    </Col>
                  </Row>
                </SkeletonTheme>
              ) : (
                (dados.missao && dados.politica && (
                  <Row>
                    <Col lg={5} className="containerValores">
                      <img className="icone" src={alvo} alt="missao" />
                      <h3 className="titulo">{dados.missao.titulo}</h3>
                      <div className="texto">
                        {parse(dados.missao.textoHtml)}
                      </div>
                    </Col>
                    <Col lg={7} className="containerValores">
                      <img className="icone" src={certificado} alt="missao" />
                      <h3 className="titulo">{dados.politica.titulo}</h3>
                      <div className="texto">
                        {parse(dados.politica.textoHtml)}
                      </div>
                    </Col>
                  </Row>
                )) || <></>
              )}

              {estado.loading ? (
                <SkeletonTheme
                  baseColor={COLORS.skeletonBaseColor}
                  highlightColor={COLORS.skeletonHighlightColor}
                >
                  <Row>
                    <Col lg={12}>
                      <Skeleton className="loadingListaValores" />
                    </Col>
                  </Row>
                </SkeletonTheme>
              ) : (
                <div>
                  <Row className="containerListaValoresBotoes">
                    <Col lg={3}>
                      <Button
                        onClick={() => {
                          estado.valores = {
                            posicionamento: true,
                          };
                          setEstado({ ...estado });
                        }}
                        className={`botaoListaValores ${
                          estado.valores.posicionamento ? "ativo" : ""
                        }`}
                      >
                        {dados.posicionamento?.titulo}
                      </Button>
                    </Col>
                    <Col lg={3}>
                      <Button
                        onClick={() => {
                          estado.valores = {
                            meioAmbiente: true,
                          };
                          setEstado({ ...estado });
                        }}
                        className={`botaoListaValores ${
                          estado.valores.meioAmbiente ? "ativo" : ""
                        }`}
                      >
                        {dados.meioAmbiente?.titulo}
                      </Button>
                    </Col>
                    <Col lg={3}>
                      <Button
                        onClick={() => {
                          estado.valores = {
                            comunidade: true,
                          };
                          setEstado({ ...estado });
                        }}
                        className={`botaoListaValores ${
                          estado.valores.comunidade ? "ativo" : ""
                        }`}
                      >
                        {dados.comunidade?.titulo}
                      </Button>
                    </Col>
                    <Col lg={12}>
                      <hr className="barra" />
                    </Col>
                  </Row>

                  <Row
                    className={`valoresListaContainer ${
                      estado.valores.posicionamento ? "ativo" : ""
                    }`}
                  >
                    <Col lg={6} className="containerCarousel">
                      {montaCarrosel(dados.posicionamento?.imagens ?? [])}
                    </Col>
                    <Col lg={6}>
                      <div className="textoValoresLista">
                        <div>
                          {parse(dados.posicionamento?.textoHtml ?? "")}
                        </div>
                      </div>
                    </Col>
                  </Row>

                  <Row
                    className={`valoresListaContainer ${
                      estado.valores.meioAmbiente ? "ativo" : ""
                    }`}
                  >
                    <Col lg={6} className="containerCarousel">
                      {montaCarrosel(dados.meioAmbiente?.imagens ?? [])}
                    </Col>
                    <Col lg={6}>
                      <div className="textoValoresLista">
                        <div>{parse(dados.meioAmbiente?.textoHtml ?? "")}</div>
                      </div>
                    </Col>
                  </Row>

                  <Row
                    className={`valoresListaContainer ${
                      estado.valores.comunidade ? "ativo" : ""
                    }`}
                  >
                    <Col lg={6} className="containerCarousel">
                      {montaCarrosel(dados.comunidade?.imagens ?? [])}
                    </Col>
                    <Col lg={6}>
                      <div className="textoValoresLista">
                        <div>{parse(dados.comunidade?.textoHtml ?? "")}</div>
                      </div>
                    </Col>
                  </Row>
                </div>
              )}
            </div>
          ) : null}
        </Container>
      </section>
    )) || <></>
  );
}

const getResumo = (htmlAsString: string) => {
  var htmlNodes = getChildNodesFromHtlmAsString(htmlAsString);
  var selectedNodes = htmlNodes.slice(0, 1);
  var selectedNodesAsHtmlString = convertHtmlNodesToString(selectedNodes);
  return convertStringHtmlToReactHtml(selectedNodesAsHtmlString);
};
