import { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import { gestor } from '../servicos/gestor';
import ReactGA from 'react-ga4';

export function configAnalytics(tracking_id: string): Boolean {
  try {
    ReactGA.initialize(tracking_id, {
      gaOptions: { cookieFlags: 'SameSite=None; Secure' },
    });
    return true;
  }catch(e: any){
    console.info("Erro when configure analytics: ", e)
    return false;
  }
}

export const TrackingRoute = () => {
  const location = useLocation()
  const [trackingId, setTrackingId] = useState<string | undefined>();

  useEffect( () => {
      gestor.getInformacoes().then(response => {
          if(!response.ga_tracking_id)    return

          const ok = configAnalytics(response.ga_tracking_id)
          if(ok)  setTrackingId(response.ga_tracking_id)

          // console.info("Google Analytics Configured: ", ok)
      })
  }, [])

  useEffect( () => {
      if(trackingId){
          ReactGA.send({
              hitType: 'pageview',
              page: window.location.pathname + window.location.search,
          });
          console.info("Google Analytics Send Event Page Change: ", {
              hitType: 'pageview',
              page: window.location.pathname + window.location.search,
          })
      }
  }, [location])

  return (<></>)
}