import { IDeserializable } from "./deserializable";
import { PostType } from "./types/type-post";

export default class BlogModel implements IDeserializable{
  
  dados?:{
    data?: Array<PostType>
    total?: number
  }

  erro?:boolean;
  
  deserialize(input: any): this {
    Object.assign(this,input);
    return this;
  }
}