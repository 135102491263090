import { TelephoneMinus } from "react-bootstrap-icons";
import { IDeserializable } from "./deserializable";
import { ImagemType } from "./types/type-imagem";

export default class BannerInternoModel implements IDeserializable{

  dados?:Array<{
    imagensArray: Array<ImagemType>
  }>

  erro?:boolean;

  deserialize(input: any): this {
    Object.assign(this,input);
    return this;
  }

}