import { Col, Container, Row } from "react-bootstrap";
import Accordion from "react-bootstrap/Accordion";
import "./secao-rodape.scss";
import * as Icon from "react-bootstrap-icons";
import logotipo from "../../../arquivos/imagens/logotipo-branca.svg";
import logotyto from "../../../arquivos/imagens/logotipo-branca.svg";
import { Link } from "react-router-dom";
import { gestor } from "../../../servicos/gestor";
import { QuemSomosClass } from "../../../modelos/quem-somos";
import { useEffect, useState } from "react";
import { type } from "@testing-library/user-event/dist/type";
import { resolveTypeReferenceDirective } from "typescript";
import Informacoes from "../../../modelos/informacoes";
import { sectionDataType } from "../../../modelos/types/type-section-nossa-unidades";

type SecaoRodapeDados = {
  address: string;
  cep: string;
  number: number;
  image: string;
  state: string;
  city: string;
  neighborhood: string;
  whatsapp: string;
};

function montaAcesso(links?: Array<{ titulo: string; link: string }>) {
  const retorno: Array<JSX.Element> = [];

  links?.forEach((link, index) => {
    retorno.push(
      <Link key={index} to={link.link} reloadDocument>
        <p>{link.titulo}</p>
      </Link>
    );
  });

  return retorno;
}

export default function SecaoRodape() {
  type SecaoRodapeType = {
    loading: boolean;
    erroAoCarregar: boolean;
    dados: {
      informacoes?: Informacoes;
      produtos?: Array<{
        titulo: string;
        link: string;
      }>;
      servicos?: Array<{
        titulo: string;
        link: string;
      }>;
      unidades?: Array<sectionDataType>;
    };
  };

  const [estado, setEstado] = useState<SecaoRodapeType>({
    loading: true,
    erroAoCarregar: false,
    dados: {},
  });

  useEffect(() => {
    gestor.getInformacoes().then((retornoInformacoes) => {
      if (retornoInformacoes.erro) {
        estado.erroAoCarregar = true;
        setEstado({ ...estado });
      }

      gestor.getMenus().then((retornoMenus) => {
        estado.dados.servicos = [];
        estado.dados.produtos = [];

        estado.dados.informacoes = retornoInformacoes;
        setEstado({ ...estado });

        retornoMenus
          .filter((menu) => menu.menu.name === "Serviços")
          .at(0)
          ?.childrens.forEach((servico) => {
            estado.dados.servicos?.push({
              titulo: servico.menu.name ?? "",
              link: servico.menu.html ?? "",
            });
          });

        retornoMenus
          .filter((menu) => menu.menu.name === "Produtos")
          .at(0)
          ?.childrens.forEach((produto) => {
            estado.dados.produtos?.push({
              titulo: produto.menu.name ?? "",
              link: produto.menu.html ?? "",
            });
          });

        estado.dados.informacoes = retornoInformacoes;
        setEstado({ ...estado });

        gestor.getNossasUnidades().then((retornoNossasUnidades) => {
          if (retornoNossasUnidades.erro) {
            estado.erroAoCarregar = true;
            setEstado({ ...estado });
          }

          estado.dados.unidades = [];

          retornoNossasUnidades.dados?.data
            ?.at(0)
            ?.section_informations.forEach((unidade) => {
              estado.dados.unidades?.push(unidade);
            });

          setEstado({ ...estado });
        });
      });
    });
  }, []);

  if (estado.erroAoCarregar) {
    return null;
  }

  return (
    <section id="SecaoRodape">
      <Container className="itemRodape">
        <Row className="desktop">
          <Col lg={3} className="imgCol">
            <div>
              <img
                className="imgRodape"
                src={estado.dados?.informacoes?.watermark ?? ""}
                alt=""
              />
            </div>
          </Col>

          <Col lg={2} className="colLink">
            <hr className="linha"></hr>
            <h5 className="itemTitulo">A Moreflex</h5>
            <Link to="/institucional">
              <p>Institucional</p>
            </Link>
            <Link to="/credenciados">
              <p>Credenciados</p>
            </Link>
            <Link to="/nossa-unidade">
              <p>Nossa Unidade</p>
            </Link>
            <Link to="/fale-conosco">
              <p>Fale Conosco</p>
            </Link>
            <Link to="/trabalhe-conosco">
              <p>Trabalhe Conosco</p>
            </Link>
          </Col>

          <Col lg={2} className="colLink">
            <hr className="linha"></hr>
            <h5 className="itemTitulo">Produtos</h5>
            {montaAcesso(estado.dados.produtos)}
          </Col>

          <Col lg={2} className="colLink">
            <hr className="linha"></hr>
            <h5 className="itemTitulo">Serviços</h5>
            {montaAcesso(estado.dados.servicos)}
          </Col>

          <Col lg={3}>
            {estado.dados.unidades?.map((unidade, index) => {
              return (
                <Row key={index} className="infoRodape">
                  <Col lg={12}>
                    <div className="icone">
                      <Icon.PinMap size={25} />
                    </div>
                  </Col>
                  <Col lg={12} className="itemTexto">
                    <h3>{unidade.section_data.titulo}</h3>
                    <p>{unidade.section_data.endereco}</p>
                    <p>Fone: {unidade.section_data.Telefone}</p>
                  </Col>
                </Row>
              );
            })}
          </Col>
        </Row>

        <Row className="desktop g-0">
          <hr className="linhaCopyright" />
          <Col lg={6} className="copyright">
            <div>© {new Date().getFullYear()} | Moreflex. Todos os direitos reservados.</div>
          </Col>
          <Col lg={6} className="tytotech">
            <a
              href="https://tytotech.com.br/"
              title="Desenvolvido por Tytotech"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src="https://tytotech.com.br/web-files/imagens/logos/logo-tytotech.svg"
                alt="Tytotech"
                width="85px"
              />
            </a>
            <span className="versao">
              {" "}
              {`${process.env.REACT_APP_VERSION}`}
            </span>
          </Col>
        </Row>

        <Row className="responsivo">
          <Col lg={12} className="itemRodape">
            <div className="imgRodape">
              <img
                src={estado.dados?.informacoes?.watermark ?? ""}
                alt="logo"
                className="logo"
              />
            </div>

            <Accordion defaultActiveKey="0" flush>
              <Accordion.Item eventKey="0">
                <Accordion.Header>MENU</Accordion.Header>
                <Accordion.Body className="menuResponsivo">
                  <hr className="linha" />
                  <h5>A MOREFLEX</h5>
                  <Link to="/institucional">
                    <p>Institucional</p>
                  </Link>
                  <Link to="/credenciados">
                    <p>Credenciados</p>
                  </Link>
                  <Link to="/nossa-unidade">
                    <p>Nossa Unidade
                      
                    </p>
                  </Link>
                  <Link to="/fale-conosco">
                    <p>Fale Conosco</p>
                  </Link>
                  <Link to="/trabalhe-conosco">
                    <p>Trabalhe Conosco</p>
                  </Link>
                  <hr className="linha" />
                  <h5>PRODUTOS</h5>


                  {montaAcesso(estado.dados.produtos)}
                  <hr className="linha" />
                  <h5>SERVIÇOS</h5>


                  {montaAcesso(estado.dados.servicos)}
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="1">
                <Accordion.Header>NOSSAS UNIDADES</Accordion.Header>
                <Accordion.Body>
                  {estado.dados.unidades?.map((unidade, index) => {
                    return (
                      <Row key={index} className="infoRodape">
                        <Col lg={11}>
                          <div className="icone">
                            <Icon.PinMap size={25} />
                          </div>

                          <div className="itemTexto">
                            <h3>{unidade.section_data.titulo}</h3>
                            <p>{unidade.section_data.endereco}</p>
                            <p>Fone: {unidade.section_data.Telefone}</p>
                          </div>
                        </Col>
                      </Row>
                    );
                  })}
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </Col>
        </Row>
        <Row className="responsivo">
          <hr className="linhaCopyright" />
          <Col lg={6} className="copyright">
            <div>© {new Date().getFullYear()} | Moreflex. Todos os direitos reservados.</div>
          </Col>
          <Col lg={6} className="tytotech">
            <a
              href="https://tytotech.com.br/"
              title="Desenvolvido por Tytotech"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src="https://tytotech.com.br/web-files/imagens/logos/logo-tytotech.svg"
                alt="Tytotech"
                width="85px"
              />
            </a>
            <span className="versao">
              {" "}
              {`${process.env.REACT_APP_VERSION}`}
            </span>
          </Col>
        </Row>
      </Container>
    </section>
  );
}
