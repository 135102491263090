import axios from "axios";
import { MenuApp, MenuAppFill } from "react-bootstrap-icons";
import Informacoes from "../modelos/informacoes";
import { MenuRec, MenuTree } from "../modelos/menu";
import BannerModel from "../modelos/model-banner";
import BannerInternoModel from "../modelos/model-banner-interno";
import { QuemSomosClass } from "../modelos/quem-somos";
import { Image } from "../modelos/image"
import BlogModel from "../modelos/model-blog";
import BLogCategoriaModel from "../modelos/model-blog-categorias";
import PostModel from "../modelos/model-post";
import SectionNossaUnidadesModel, { SectionCredenciadosModel, SectionData, SectionDataIndex } from "../modelos/sections";
import { CategoriaProduto, CategoriasProdutoResponse } from "../modelos/categorias-produto";
import { CaracteristicaProduto, Produto } from "../modelos/produto";
import { GestorResponse } from "../modelos/gestor-response";
import { Paginacao } from "../modelos/paginacao";
import { CategoriasType } from "../modelos/types/type-categorias";
import ServicosModel from "../modelos/model-servicos";
import ServicoModel from "../modelos/model-servico";
import { ProdutoServicoType } from "../modelos/types/type-produto";
import { FiltroCaracteristica } from "../modelos/types/type-filtro-caracteristica";
import { ContatoStatus, ContatoTrabalheConoscoType, ContatoType } from "../modelos/types/types-contato";

const api = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/site`,
  headers: {
    company: `${process.env.REACT_APP_COMPANY_ID}`,
    Accept: 'application/json',
  }
});

const getMenus = async (): Promise<MenuTree[]> => {
  try {
    const { data, status } = await api.get<any>(`/menus`);

    var mapper = new Map<number, MenuTree>()
    var array: MenuTree[] = []

    data.forEach((item: any) => {
      var menu = new MenuRec().deserialize({
        id: item.id,
        name: item.name,
        description: item.description,
        html: item.html,
        isLink: item.isLink,
        image: item.image,
        menuId: item.menu_id
      })

      var tree = {
        menu: menu,
        childrens: []
      }

      if (tree.menu.id) {
        mapper.set(tree.menu.id, tree)
        if (menu.menuId) {
          var father = mapper.get(menu.menuId)
          if (father) {
            menu.html = menu.html!
            // menu.html = father.menu.html + menu.html! // Usar caso queira contruir o endpoint a partir das urls dos pais
            father.childrens.push(tree)
          }
        } else {
          array.push(tree)
        }
      }
    });

    return array;

  } catch (error) {
    if (axios.isAxiosError(error)) {
      console.warn('error on getBlogById - message: ', error.message);
      return [];
    } else {
      console.warn('unexpected error on getBlogById: ', error);
      return [];
    }
  }
}

const getSections = async (section: string): Promise<SectionData[]> => {
  try {
    const { data, status } = await api.get<any>(`/secao/${section}`);
    var sections: SectionData[] = []

    data.data.forEach((item: any) => sections.push(new SectionData().deserialize(item)))

    return sections;
  } catch (error) {

    if (axios.isAxiosError(error)) {
      console.warn("error on getSection - message: ", error.message);
    } else {
      console.warn("unexpected error on getSection ", error);
    }

    return [];
  }
}

const getSectionsIndex = async (): Promise<SectionDataIndex[]> => {
  try {
    const { data, status } = await api.get<any>(`/secaoIndex`);
    var sections: SectionDataIndex[] = []

    data.data.forEach((item: any) => sections.push(new SectionDataIndex().deserialize(item)))

    return sections;
  } catch (error) {

    if (axios.isAxiosError(error)) {
      console.warn("error on getSection - message: ", error.message);
    } else {
      console.warn("unexpected error on getSection ", error);
    }

    return [];
  }
}

const getInformacoes = async (): Promise<Informacoes> => {

  const retorno = new Informacoes;

  try {

    const { data, status } = await api.get("/informacoes") as any;

    return retorno.deserialize(data);

  } catch (error) {

    if (axios.isAxiosError(error)) {
      console.warn("error on informacoes - message: ", error.message);
    } else {
      console.warn("unexpected error on informacoes: ", error);
    }

    return retorno.deserialize({erro:true});
  }
}

const getBanners = async (): Promise<BannerModel> => {

  const retorno = new BannerModel;

  try {

    const { data, status } = await api.get("/banners") as any;

    return retorno.deserialize({ dados: data });

  } catch (error) {

    console.warn(axios.isAxiosError(error) ?
      `Erro em banner - mensagem: ${error.message}` :
      `Erro não esperado em banner - mensagem: ${error}`);

    return retorno.deserialize({ erro: true });
  }

}

const getBannerInterno = async (pagina: string): Promise<BannerInternoModel> => {

  const retorno = new BannerInternoModel;

  try {

    const { data, status } = await api.get(`/bannerInterno?page=${pagina}`);

    return retorno.deserialize({ dados: data });

  } catch (error) {

    console.warn(axios.isAxiosError(error) ?
      `Erro em banner interno - mensagem: ${error.message}` :
      `Erro não esperado em banner interno - mensagem: ${error}`);

    return retorno.deserialize({ erro: true });
  }

}

const getQuemSomos = async (): Promise<QuemSomosClass | undefined> => {
  try {
    const { data, status } = await api.get<any>(`/quem_somos`);

    var imagens: Image[] = data.imagensArray.map((img: any) => {
      var image: Image = new Image().deserialize({
        src: img.src,
        showFirst: img.showFirst
      });

      return image;
    })

    return new QuemSomosClass().deserialize({
      id: data.id,
      title: data.title,
      descriptionAsHtml: data.text,
      imagens: imagens,
      youtubeLink: data.youtube_link
    });

  } catch (error) {
    if (axios.isAxiosError(error)) {
      console.warn('error on getQuemSomos - message: ', error.message);
      return undefined;
    } else {
      console.warn('unexpected error on getQuemSomos: ', error);
      return undefined;
    }
  }
}

const getCategoriaPorId = async (id: number): Promise<GestorResponse<CategoriaProduto | undefined>> => {
  const response = await getCategoriasProdutoServico()
  const categoryWithSameId = response.dados.filter(cat => Number(cat.id) === id)

  if (!response.error && categoryWithSameId.length)
    return new GestorResponse(false, categoryWithSameId[0])
  return new GestorResponse(true)

}

const getCategoriasProdutoServico = async (): Promise<CategoriasProdutoResponse> => {
  try {
    const { data, status } = await api.get<any>(`/categorias`);

    var dados: CategoriaProduto[] = data.map((obj: any): CategoriaProduto => {
      return {
        id: obj.id,
        title: obj.title ? obj.title : '',
        descriptionHtml: obj.description ? obj.description : '',
        image: obj.image ? obj.image : '',
        category_id: obj.category_id,
        quantity: obj.quantity ? obj.quantity : 0,
        type: obj.type
      }

    })

    var retorno = new CategoriasProdutoResponse();
    retorno.dados = dados;

    return retorno;

  } catch (error) {
    if (axios.isAxiosError(error)) {
      console.warn('error on getCategoriasProduto - message: ', error.message);
      return new CategoriasProdutoResponse().deserialize({ error: true });
    } else {
      console.warn('unexpected error on getCategoriasProduto: ', error);
      return new CategoriasProdutoResponse().deserialize({ error: true });
    }
  }
}

const getProdutosPorCategoria = async (pagina: number, limite: number, categoriaId: number): Promise<GestorResponse<Paginacao<Produto>>> => {
  try {
    const { data } = await api.get<any>(`/produtos_servicos?page=${pagina}&limit=${limite}&category=${categoriaId}`)

    const JSONtoProduto = (obj: any): Produto | undefined => {
      try {
        return {
          id: obj.id,
          titulo: obj.title,
          descricaoHtml: obj.description,
          especificacaoHtml: obj.specification,
          categoria: obj.category,
          marca: obj.brand,              // undefined type
          compania: obj.company,
          criadoEm: obj.createdAt,
          atualizadoEm: obj.updatedAt,
          disabilitado: obj.disabled ?? true,
          botaoTexto: obj.button_text ?? '',
          botaoTitulo: obj.button_title ?? ',',
          ehServico: obj.is_service,
          caracteristicas: obj.characteristics_product_services ?? [],
          imagens: obj.imagensArray,
          arquivos: obj.arquivosArray,
        }
      } catch (error) {
        console.warn('error on JSONtoProduto - message: ', error)
        return undefined
      }
    }

    var resultados: Produto[] = data.data.map(JSONtoProduto)

    var paginacao = new Paginacao<Produto>(pagina, limite, data.total, Math.ceil(data.total / limite), resultados)

    return new GestorResponse<Paginacao<Produto>>(false, paginacao)
  } catch (error) {
    if (axios.isAxiosError(error)) {
      console.warn('error on getProdutosPorCategoria - message: ', error.message)
      return new GestorResponse<Paginacao<Produto>>(true)
    } else {
      console.warn('unexpected error on getProdutosPorCategoria: ', error)
      return new GestorResponse<Paginacao<Produto>>(true)
    }
  }
}

const getProdutosFiltradosPorCaracteristicas = async (pagina: number, limite: number, categoriaId: number, caracristicasIds: number[]): Promise<GestorResponse<Paginacao<Produto>>> => {
  try {

    var queries = ''

    for (let c of caracristicasIds){
      queries += `&characteristics=${c}`
    }

    const { data } = await api.get<any>(`/produtos_servicos?page=${pagina}&limit=${limite}&category=${categoriaId}`+queries)

    const JSONtoProduto = (obj: any): Produto | undefined => {
      try {
        return {
          id: obj.id,
          titulo: obj.title,
          descricaoHtml: obj.description,
          especificacaoHtml: obj.specification,
          categoria: obj.category,
          marca: obj.brand,              // undefined type
          compania: obj.company,
          criadoEm: obj.createdAt,
          atualizadoEm: obj.updatedAt,
          disabilitado: obj.disabled ?? true,
          botaoTexto: obj.button_text ?? '',
          botaoTitulo: obj.button_title ?? ',',
          ehServico: obj.is_service,
          caracteristicas: obj.characteristics_product_services ?? [],
          imagens: obj.imagensArray,
          arquivos: obj.arquivosArray,
        }
      } catch (error) {
        console.warn('error on JSONtoProduto - message: ', error)
        return undefined
      }
    }

    var resultados: Produto[] = data.data.map(JSONtoProduto)

    var paginacao = new Paginacao<Produto>(pagina, limite, data.total, Math.ceil(data.total / limite), resultados)

    return new GestorResponse<Paginacao<Produto>>(false, paginacao)
  } catch (error) {
    if (axios.isAxiosError(error)) {
      console.warn('error on getProdutosPorCategoriaFiltrados - message: ', error.message)
      return new GestorResponse<Paginacao<Produto>>(true)
    } else {
      console.warn('unexpected error on getProdutosPorCategoriaPFiltrados: ', error)
      return new GestorResponse<Paginacao<Produto>>(true)
    }
  }
}

const getProdutoById = async (id: number): Promise<GestorResponse<ProdutoServicoType>> => {
  try {
    const { data } = await api.get<any>(`/produtos_servicos/${id}`);

    const produtoServico: ProdutoServicoType = data as ProdutoServicoType

    return new GestorResponse<ProdutoServicoType>(false, produtoServico)
  } catch (error) {
    if (axios.isAxiosError(error)) {
      console.warn('error on getProdutosPorCategoria - message: ', error.message)
      return new GestorResponse<ProdutoServicoType>(true)
    } else {
      console.warn('unexpected error on getProdutosPorCategoria: ', error)
      return new GestorResponse<ProdutoServicoType>(true)
    }
  }
}

const getBlog = async (pagina?: string | number, limit?: string| number, category?: string| number): Promise<BlogModel> => {

  const retorno = new BlogModel;

  try {

    const pesquisa = pagina && limit && category ? `/blog?page=${pagina}&limit=${limit}&category=${category}` :
      pagina && limit ? `/blog?page=${pagina}&limit=${limit}` :
        `/blog`

    const { data, status } = await api.get(pesquisa);

    return retorno.deserialize({ dados: data });

  } catch (error) {

    console.warn(axios.isAxiosError(error) ?
      `Erro em blog - mensagem: ${error.message}` :
      `Erro não esperado em blog - mensagem: ${error}`);

    return retorno.deserialize({ erro: true });
  }
}

const getBlogCategorias = async (): Promise<BLogCategoriaModel> => {

  const retorno = new BLogCategoriaModel;

  try {

    const { data, status } = await api.get(`/blog/categorias`);

    return retorno.deserialize({ dados: data });

  } catch (error) {

    console.warn(axios.isAxiosError(error) ?
      `Erro em blog categorias - mensagem: ${error.message}` :
      `Erro não esperado em blog categorias - mensagem: ${error}`);

    return retorno.deserialize({ erro: true });
  }
}

const getPost = async (id: number | string): Promise<PostModel> => {

  const retorno = new PostModel;

  try {

    const { data } = await api.get(`/blog/${id}`);

    return retorno.deserialize({ dados: data });

  } catch (error) {

    console.warn(axios.isAxiosError(error) ?
      `Erro em post - mensagem: ${error.message}` :
      `Erro não esperado em post - mensagem: ${error}`);

    return retorno.deserialize({ erro: true });

  }

}

const getCategoriasComFiltro = async (filtroType: string): Promise<GestorResponse<Array<CategoriasType>>> => {

  try {

    const { data } = await api.get(`/categorias`);

    const dataPosFiltro = data.filter((categoria: CategoriasType) => categoria.type === filtroType);

    return new GestorResponse<Array<CategoriasType>>(false, dataPosFiltro);

  } catch (error) {

    console.warn(axios.isAxiosError(error) ?
      `Erro em categorias - mensagem: ${error.message}` :
      `Erro não esperado em categorias - mensagem: ${error}`);

    return new GestorResponse<Array<CategoriasType>>(true);
  }

}

const getServicos = async (pagina?: string | number, limit?: string | number, category?: string | number): Promise<ServicosModel> => {

  const retorno = new ServicosModel;

  try {

    const pesquisa = pagina && limit && category ? `/servicos?page=${pagina}&limit=${limit}&category=${category}` :
      pagina && limit ? `/servicos?page=${pagina}&limit=${limit}` :
        `/servicos`

    const { data, status } = await api.get(pesquisa);

    return retorno.deserialize({ dados: data });

  } catch (error) {

    console.warn(axios.isAxiosError(error) ?
      `Erro em servicos - mensagem: ${error.message}` :
      `Erro não esperado em servicos - mensagem: ${error}`);

    return retorno.deserialize({ erro: true });
  }

}

const getServico = async (id: number | string): Promise<ServicoModel> => {

  const retorno = new ServicoModel;

  try {

    const { data } = await api.get(`/produtos_servicos/${id}`);

    return retorno.deserialize({ dados: data });

  } catch (error) {

    console.warn(axios.isAxiosError(error) ?
      `Erro em servico - mensagem: ${error.message}` :
      `Erro não esperado em servico - mensagem: ${error}`);

    return retorno.deserialize({ erro: true });

  }
}

const getFiltrosCaracteristicas = async (category?:number, search?:number ): Promise<GestorResponse<FiltroCaracteristica[]>> => {
  try{

    const {data} = await api.get(`/produtos_servicos_caracteristicas?category=${category ? category : ''}&search=${search ? search : ''}`)

    return new GestorResponse<FiltroCaracteristica[]>(false, data as FiltroCaracteristica[])
  
  }catch(error){
    
    console.warn(axios.isAxiosError(error) ? 
      `Erro em getFiltrosCaracteristicas - mensagem: ${error.message}` :
      `Erro não esperado em getFiltrosCaracteristicas - mensagem: ${error}`);

    return new GestorResponse(false);
  }
}

const postContato = async(body : ContatoType): Promise<ContatoStatus> =>{

  try {

    const { data, status } = await api.post(`/contato`,body) as any;
    
    return ContatoStatus.OK

  } catch (error) {

    if (axios.isAxiosError(error)) {

      console.warn("Error on Contato - message: ", error.message);
      return ContatoStatus.ERROR;

    }

    console.warn("Unexpect error on Contato: ", error);
    return ContatoStatus.ERROR;
  }
}

const postTrabalheConosco = async(body : FormData): Promise<ContatoStatus> =>{

  try {

    const url = `${process.env.REACT_APP_API_URL}/site/trabalhe-conosco`

    await fetch(url, {
      method: 'POST',
      headers: {
        company: `${process.env.REACT_APP_COMPANY_ID}`,
        Accept: 'application/json',
      },
      body: body
   })
   .then((response) => {
      if (response.ok) {
        return response.json();
      }
      throw new Error('Something went wrong');
    })

    return ContatoStatus.OK

  } catch (error) {

    if (axios.isAxiosError(error)) {

      console.warn("Error on Contato - message: ", error.message);
      return ContatoStatus.ERROR;

    }

    console.warn("Unexpect error on Contato: ", error);
    return ContatoStatus.ERROR;
  }
}

const getNossasUnidades = async(): Promise<SectionNossaUnidadesModel> =>{

  const retorno = new SectionNossaUnidadesModel;

  try {

    const { data } = await api.get(`/secao/nossas-unidades`);

    return retorno.deserialize({ dados: data });

  } catch (error) {

    console.warn(axios.isAxiosError(error) ?
      `Erro em secao nossas unidades - mensagem: ${error.message}` :
      `Erro não esperado em secao nossas unidades - mensagem: ${error}`);

    return retorno.deserialize({ erro: true });
  }
}

const getCredenciados = async(): Promise<SectionCredenciadosModel> =>{

  const retorno = new SectionCredenciadosModel;

  try {

    const { data } = await api.get(`/secao/credenciados`);

    return retorno.deserialize({ dados: data });

  } catch (error) {

    console.warn(axios.isAxiosError(error) ?
      `Erro em secao credenciados - mensagem: ${error.message}` :
      `Erro não esperado em secao credenciados - mensagem: ${error}`);

    return retorno.deserialize({ erro: true });
  }
}

export const gestor = {
  getMenus,
  getInformacoes,
  getQuemSomos,
  getBanners,
  getBannerInterno,
  getBlog,
  getBlogCategorias,
  getPost,
  getSections,
  getSectionsIndex,
  getCategoriasProdutoServico,
  getProdutosPorCategoria,
  getCategoriaPorId,
  getCategoriasComFiltro,
  getServicos,
  getServico,
  getProdutoById,
  getFiltrosCaracteristicas,
  getProdutosFiltradosPorCaracteristicas,
  postContato,
  postTrabalheConosco,
  getNossasUnidades,
  getCredenciados
  // Colocar as funções a serem exportadas
}