import './secao-servico.scss'
import ComponenteNaoEncotrado from '../../componente-nao-econtrado/componente-nao-econtrado';
import Carousel from 'react-multi-carousel';
import { Col, Container, ListGroup, Row } from 'react-bootstrap';
import placeHolder2 from '../../../arquivos/imagens/place-holder-2.svg'
import { Columns } from 'react-bootstrap-icons';
import { useEffect, useState } from 'react';
import { gestor } from '../../../servicos/gestor';
import { ImagemType } from '../../../modelos/types/type-imagem';
import { useLocation, useParams } from 'react-router-dom';
import { ServicoType } from '../../../modelos/types/type-servico';
import HTMLReactParser from 'html-react-parser';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import { COLORS } from '../../../temas/cores';

export type comportamentoSecaoServico =
  'servico' | null;

const montaCarrosel = (listaImagens?: Array<ImagemType>) => {
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 1
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  };

  const imagensElement: Array<JSX.Element> = [];

  listaImagens?.forEach((imagens, index) => {
    imagensElement.push(
      <img key={index} src={imagens.src} alt="imagemCarousel" className='imagemCarousel' />
    );
  });

  return (
    <Carousel responsive={responsive}
      className='carousel'
      showDots={true}
      dotListClass={"dots "+`dot${imagensElement.length}`}
      removeArrowOnDeviceType={['tablet', 'mobile']}>
      {imagensElement}
    </Carousel>
  )
}

export default function SecaoServico({ comportamento }: { comportamento: comportamentoSecaoServico }) {

  type SecaoServicoType = {
    loading: boolean,
    erroAoCarregar: boolean,
    dados?: ServicoType
  }

  const [estado, setEstado] = useState<SecaoServicoType>({
    loading: true,
    erroAoCarregar: false,
  });

  const local = useLocation();

  const { id } = useParams();

  useEffect(() => {

    setEstado({loading: true, erroAoCarregar: false})

    gestor.getServico(id ?? "").then((retorno) => {

      if (retorno.erro) {
        estado.erroAoCarregar = true;
        setEstado({ ...estado });
      }

      estado.dados = retorno.dados;
      estado.loading = false;

      setEstado({ ...estado });

    });

  }, [id])

  if (estado.erroAoCarregar) {
    return null;
  }

  return (
    <section id="SecaoServico">

      {estado.loading ?
        <Container className="containerLoading">
          <SkeletonTheme baseColor={COLORS.skeletonBaseColor} highlightColor={COLORS.skeletonHighlightColor}>
            <Row>
              <Col xs={12} md={5}>
                <Skeleton className="carouselLoading" />
              </Col>
              <Col xs={12} md={7}>
                <Skeleton className="tituloLoading" />
                <Skeleton className="textoLoading" count={15}/>
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={5}>
                <Skeleton className="especificacaoLoading" count={5}/>
              </Col>
            </Row>
          </SkeletonTheme>
        </Container>
        :
        <Container>
          <Row className='coldesc'>
            <Col xs={12} md={5} className='containerCarousel'>
              {montaCarrosel(estado.dados?.imagensArray)}
            </Col>
            <Col xs={12} md={7}>
              <h2>
                {estado.dados?.title}
              </h2>
              {HTMLReactParser(estado.dados?.description ?? "")}
            </Col>
          </Row>
          <Row className='coldescp'>
            <Col xs={12} md={5}>
              {HTMLReactParser(estado.dados?.specification ?? "")}
            </Col>
          </Row>
        </Container>}

    </section>
  );
}