import { IDeserializable } from "./deserializable";
import { ServicoType } from "./types/type-servico";

export default class ServicoModel implements IDeserializable {

  dados?:ServicoType

  erro?:boolean;

  deserialize(input: any): this {
    Object.assign(this, input);
    return this;
  }
}