import "./secao-listagem-produtos.scss";

import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { COLORS } from "../../../temas/cores";
import {
  Accordion,
  Button,
  Card,
  Col,
  Container,
  Row,
  useAccordionButton,
} from "react-bootstrap";
import { useEffect, useLayoutEffect, useRef, useState } from "react";

import parse from "html-react-parser";
import { Link, useParams } from "react-router-dom";
import { ArrowRight } from "react-bootstrap-icons";
import { Seletores } from "../../seletores/seletores";
import { gestor } from "../../../servicos/gestor";
import { Produto } from "../../../modelos/produto";

import logo from "../../../arquivos/imagens/logo.svg";
import {
  converterParaUrlAmigavel,
  scrolldiv,
} from "../../../utilidades/htmlTools";
import { CategoriaProduto } from "../../../modelos/categorias-produto";
import { FiltroCaracteristica } from "../../../modelos/types/type-filtro-caracteristica";

type comportamentoSecaoListagemProdutos = "produto" | "produtos";

type Estado = {
  carregando: boolean;
  erro: boolean;
};

type CardListagem = {
  imagem: string;
  nome: string;
  link: string;
};

type CategoriaInfo = {
  imagem: string;
  titulo: string;
  textoHtml: string;
};

type DadoMenu = {
  nome: string;
  options: string[];
};

type ControlePagina = {
  pagina: number;
  total: number;
  itensPorPagina: number;
};

export default function SecaoListagemProdutos({
  comportamento,
}: {
  comportamento: comportamentoSecaoListagemProdutos;
}) {
  const [paginaControle, setPaginaControle] = useState<ControlePagina>({
    pagina: 1,
    total: 0,
    itensPorPagina: 6,
  });

  const { idCategoria, nomeCategoria } = useParams();

  const [estadoCategoria, setEstadoCategoria] = useState<Estado>({
    carregando: true,
    erro: false,
  });
  const [estadoListagem, setEstadoListagem] = useState<Estado>({
    carregando: true,
    erro: false,
  });
  const [estadoMenu, setEstadoMenu] = useState<Estado>({
    carregando: true,
    erro: false,
  });

  const [dadosMenu, setDadosMenu] = useState<FiltroCaracteristica[]>([]);

  const [dadosCategoria, setDadosCategoria] = useState<CategoriaInfo>();
  const [cards, setCards] = useState<CardListagem[]>([]);

  const [filtroCaracteristicas, setFiltroCaracteristicas] = useState<number[]>(
    []
  );

  const filtrarProdutos = (caracteristicasIds: number[], page: number = 1) => {
    setFiltroCaracteristicas(caracteristicasIds);
    setEstadoListagem({ carregando: true, erro: false });
    if (caracteristicasIds.length)
      buscarProdutosFiltrados(caracteristicasIds, page);
    else buscarTodosProdutos();
  };

  const buscarTodosProdutos = () => {
    gestor
      .getProdutosPorCategoria(
        paginaControle.pagina,
        paginaControle.itensPorPagina,
        Number(idCategoria ?? -1)
      )
      .then((res) => {
        const produtoToCard = (p: Produto): CardListagem => {
          return {
            imagem: p.imagens[0].src ?? logo,
            nome: p.titulo ?? "",
            link: `/produtos/${idCategoria}/${nomeCategoria}/${
              p.id
            }/${converterParaUrlAmigavel(p.titulo ?? "")}`,
          };
        };

        res.dados && setCards(res.dados?.resultados.map(produtoToCard));
        res.dados && setEstadoListagem({ carregando: false, erro: false });
        res.dados &&
          setPaginaControle({
            ...paginaControle,
            total: res.dados.totalResultados,
          });
      });
  };

  const buscarProdutosFiltrados = (caracteristicas: number[], page: number) => {
    setPaginaControle({ ...paginaControle, pagina: page });
    gestor
      .getProdutosFiltradosPorCaracteristicas(
        page,
        paginaControle.itensPorPagina,
        Number(idCategoria ?? -1),
        caracteristicas
      )
      .then((res) => {
        const produtoToCard = (p: Produto): CardListagem => {
          return {
            imagem: p.imagens[0].src ?? logo,
            nome: p.titulo ?? "",
            link: `/produtos/${idCategoria}/${nomeCategoria}/${
              p.id
            }/${converterParaUrlAmigavel(p.titulo ?? "")}`,
          };
        };

        res.dados && setCards(res.dados?.resultados.map(produtoToCard));
        res.dados && setEstadoListagem({ carregando: false, erro: false });
        res.dados &&
          setPaginaControle({
            ...paginaControle,
            total: res.dados.totalResultados,
            pagina: page,
          });
      });
  };
  useEffect(() => {
    setEstadoMenu({ carregando: true, erro: false });
    setEstadoListagem({ carregando: true, erro: false });
    setDadosMenu([]);
    if (idCategoria)
      gestor.getFiltrosCaracteristicas(Number(idCategoria)).then((response) => {
        if (!response.error) {
          setDadosMenu(response.dados!);
          setEstadoMenu({ carregando: false, erro: false });
        } else setEstadoMenu({ carregando: true, erro: true });
      });
  }, [idCategoria]);

  const changePageHandler = (index: number) => {
    setEstadoListagem({ carregando: true, erro: false });
    scrolldiv(
      "ancora-listagem-produtos-por-categoria",
      window.innerWidth > 992 ? -200 : -150
    );
    buscarProdutosFiltrados(filtroCaracteristicas, index);
  };

  useEffect(() => {
    setEstadoCategoria({ carregando: true, erro: false });
    gestor.getCategoriaPorId(Number(idCategoria ?? -1)).then((res) => {
      const categoriaProdutoToCategoriaInfo = (
        cp: CategoriaProduto
      ): CategoriaInfo => {
        return {
          imagem: cp.image,
          titulo: cp.title,
          textoHtml: cp.descriptionHtml,
        };
      };

      if (!res.error) {
        setDadosCategoria(
          res.dados ? categoriaProdutoToCategoriaInfo(res.dados) : undefined
        );
        setEstadoCategoria({ carregando: false, erro: false });
      } else setEstadoCategoria({ carregando: false, erro: true });
    });
  }, [idCategoria]);

  useEffect(() => {
    buscarTodosProdutos();
  }, [idCategoria]);

  let listagemSemFiltro = idCategoria == "22";

  return (
    <SkeletonTheme
      baseColor={COLORS.skeletonBaseColor}
      highlightColor={COLORS.skeletonHighlightColor}
    >
      <section id="SecaoListagemProdutos">
        <Container>
          <CategoriaInfoSection
            dados={dadosCategoria}
            estado={estadoCategoria}
          />
          <Row className="produtosListagem">
            {!listagemSemFiltro ? (
              <Col xs={12} lg={3}>
                <Accordion className="accordion">
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>Filtros</Accordion.Header>
                    <Accordion.Body>
                      <Menus
                        menus={dadosMenu}
                        estado={estadoMenu}
                        filtrar={filtrarProdutos}
                      />
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>

                <div className="filtro">
                  <Menus
                    menus={dadosMenu}
                    estado={estadoMenu}
                    filtrar={filtrarProdutos}
                  />
                </div>
              </Col>
            ) : null}

            <Col xs={12} lg={listagemSemFiltro ? 12 : 9} className="listagem">
              <div id="ancora-listagem-produtos-por-categoria"></div>
              <CardsListagem
                cards={cards}
                estado={estadoListagem}
                linkOff={listagemSemFiltro}
              />
              {paginaControle.total > paginaControle.itensPorPagina && (
                <Seletores
                  total={paginaControle.total}
                  itemsPerPage={paginaControle.itensPorPagina}
                  selected={paginaControle.pagina}
                  middle={3}
                  fixeds={1}
                  changePage={changePageHandler}
                />
              )}
            </Col>
          </Row>
        </Container>
      </section>
    </SkeletonTheme>
  );
}

const CategoriaInfoSection = ({
  dados,
  estado,
}: {
  dados?: CategoriaInfo;
  estado: Estado;
}) => {
  if (estado.erro) return <></>;
  return estado.carregando ? (
    <SkelentonCategoriaInfo />
  ) : dados ? (
    <Row className="categoriaInfo">
      <Col xs={12} lg={5} className="img">
        <img src={dados.imagem} alt="imagem da categoria" />
      </Col>
      <Col xs={12} lg={7} className="descricao">
        <h1 className="titulo">{dados.titulo}</h1>
        <p className="texto">{parse(dados.textoHtml)}</p>
      </Col>
    </Row>
  ) : (
    <></>
  );
};

const CardsListagem = ({
  cards,
  estado,
  linkOff,
}: {
  cards: CardListagem[];
  estado: Estado;
  linkOff: boolean;
}) => {
  if (estado.erro) return <></>;
  return estado.carregando ? (
    <SkeletonCardsListagem />
  ) : (
    <Container fluid>
      <Row>
        {cards.length ? (
          cards.map((card, i) => (
            <Col xs={12} lg={4} key={i} className="cardContainer">
              {!linkOff ? (
                <Link to={card.link} className="link">
                  <Card className="card">
                    <Card.Img
                      className="imagem"
                      variant="top"
                      src={card.imagem}
                    />
                    <Card.Body
                      className={`cardBody ${linkOff ? "linkOff" : ""}`}
                    >
                      <Card.Title>
                        <h2 className="titulo">{card.nome}</h2>
                      </Card.Title>
                      <Button className="botao">
                        Ver Detalhes
                        <ArrowRight className="seta" size={30} />
                      </Button>
                    </Card.Body>
                  </Card>
                </Link>
              ) : null}
            </Col>
          ))
        ) : (
          <Col xs={12} className="naoEncontrado">
            Nenhum produto encontrado !
          </Col>
        )}
      </Row>
    </Container>
  );
};

type ControleOption = {
  id: number;
  checked: boolean;
};

const Menus = ({
  menus,
  estado,
  filtrar,
}: {
  menus: FiltroCaracteristica[];
  estado: Estado;
  filtrar: (caracteristicasIds: number[], page: number) => void;
}) => {
  const [menuControl, setMenuControl] = useState<ControleOption[][]>([]);

  const [humanClick, setHumanClick] = useState<boolean>(false);

  const ref = useRef(null);

  useEffect(() => setHumanClick(false), [menus]);
  useLayoutEffect(() => {
    // -------- CRIA UM ARRAY QUE SERÁ USADO PARA CONTROLAR E MONITORAR OS CHECK BOXES ---------
    var controle: ControleOption[][] = [];
    for (let c of menus) {
      var optionsControle: ControleOption[] = [];
      for (let opt of c.characteristics) {
        optionsControle.push({ id: opt.id, checked: false });
      }
      controle.push(optionsControle);
    }
    setMenuControl([...controle]);
    // ---------------------------- END CRIAR CONTROLE CHECK BOXES -----------------------------
  }, [menus]);

  useEffect(() => {
    if (menuControl.length && menus.length && humanClick) {
      var caracteristicas: number[] = [];

      for (let c of menuControl)
        for (let opt of c) if (opt.checked) caracteristicas.push(opt.id);
      filtrar(caracteristicas, 1);
    }
  }, [menuControl]);

  const trocarValorEGarantirUnicoChecked = (
    idxPai: number,
    idxOption: number,
    value: boolean
  ) => {
    var value = !menuControl[idxPai][idxOption].checked;
    console.log("trocarValorEGarantirUnicoChecked");
    menuControl[idxPai].forEach((option: ControleOption, i: number) => {
      if (i != idxOption) option.checked = false;
      else option.checked = value;
    });

    setMenuControl([...menuControl]);
  };

  useEffect(() => console.log(menuControl), [menuControl]);

  if (estado.erro) return <></>;
  return estado.carregando ? (
    <SkeletonsMenu />
  ) : (
    <Row>
      {menus.map((menu, i) => (
        <Col xs={12} className="colMenu" key={i}>
          <div className={`menu ${i != menus.length - 1 ? "comLinha" : ""}`}>
            <div className="titulo">{menu.name}</div>
            <div className="options">
              {menuControl &&
                menuControl[i] &&
                menu.characteristics.map((option, opIdx) => (
                  <div className="option" key={opIdx}>
                    {menuControl[i][opIdx] &&
                      menuControl[i][opIdx].checked !== undefined && (
                        <>
                          <input
                            type="checkbox"
                            id={i + "checkbox" + opIdx}
                            name={i + "checkbox" + opIdx}
                            onChange={(e) => {
                              trocarValorEGarantirUnicoChecked(
                                i,
                                opIdx,
                                e.target.checked
                              );
                            }}
                            onClick={() => {
                              console.log("setHumanCLick");
                              setHumanClick(true);
                            }}
                            checked={
                              menuControl[i]
                                ? menuControl[i][opIdx].checked
                                : false
                            }
                          />
                          <label
                            onClick={() => {
                              setHumanClick(true);
                              trocarValorEGarantirUnicoChecked(i, opIdx, true);
                            }}
                          >
                            {option.description}
                          </label>
                        </>
                      )}
                  </div>
                ))}
            </div>
          </div>
        </Col>
      ))}
    </Row>
  );
};

// -------------------- Skeletons -----------------------

const SkelentonCategoriaInfo = () => {
  return (
    <>
      <div className="skeletonCategoria">
        <Skeleton width={"100%"} height={"100%"} />
      </div>
      <div className="skeletonCategoriaInfo">
        <Skeleton width={"100%"} height={"100%"} />
      </div>
    </>
  );
};

const SkeletonCardsListagem = () => {
  return (
    <Container fluid className="skeletonCardsListagem">
      <Row>
        {Array(6)
          .fill(null)
          .map((v, i) => (
            <Col xs={12} lg={4} key={i} className="skeletonCard">
              <Skeleton width={"100%"} height={"100%"} />
            </Col>
          ))}
      </Row>
    </Container>
  );
};

const SkeletonsMenu = () => {
  return (
    <Row>
      {Array(4)
        .fill(null)
        .map((v, i) => (
          <Col xs={12} key={i} className="skeletonMenu">
            <Skeleton width={"100%"} height={"100%"} />
          </Col>
        ))}
    </Row>
  );
};
