import parse from 'html-react-parser';
/**
 * Apenas um mapper para htmlEvent.preventDefault
 * @param event 
 */
export function pd(event: any) {
  event.preventDefault();
}

export function convertStringHtmlToReactHtml(htmlAsString: string): string | JSX.Element | JSX.Element[] {
  return parse(htmlAsString);
}

export const toTop = () => {
  document.documentElement.scrollTop = 0;
}

export function convertHtmlNodesToString(htmlNodeList: any): string {
  var str = '';
  htmlNodeList.forEach((child: any) => {
    if (child.outerHTML)
      str += child.outerHTML;
  });
  return str;
}

export function getChildNodesFromHtlmAsString(text: string): ChildNode[] {
  var res = new DOMParser().parseFromString(text, 'text/html')!.body!.childNodes;
  var childs = new Array<ChildNode>();

  res.forEach(e => childs.push(e));

  return childs;
}

/**
 *  Retorna todo o texto contido no html pai e seu filhos convertidos usando htmlAsString
 **/
export function getStringFromHtml(htmlAsString: string): string {
  var res = new DOMParser().parseFromString(htmlAsString, 'text/html')!.body!.textContent;
  return res ? res : ''
}

const findPosition = (obj: any) => {
  var currenttop = 0;
  if (obj.offsetParent) {
    do {
      currenttop += obj.offsetTop;
    } while ((obj = obj.offsetParent));
    return [currenttop];
  }
}

export const scrolldiv = (id: string, overflow: number) => {
  window.scroll(0,
    findPosition(document.getElementById(id))![0] + overflow);
}

export function converterParaUrlAmigavel(url: string): string {
  return url.toLowerCase()
    .replace(/[áàãâä]/g, 'a')
    .replace(/[éèẽêë]/g, 'e')
    .replace(/[íìîĩï]/g, 'i')
    .replace(/[óòõôö]/g, 'o')
    .replace(/[úùũûü]/g, 'u')
    .replace(/[ç]/g, 'c')
    .replace(/['"!@#$%¨&*()-+:<>=|\\/ºª°?]/g, '')
    .replace(/ /g, '-')
}