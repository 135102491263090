import { IDeserializable } from "./deserializable";
import { BannerType } from "./types/type-banner";

export default class BannerModel implements IDeserializable{

  dados ?: Array<BannerType>;
  erro ?: boolean;

  deserialize(input: any): this {
    Object.assign(this,input);
    return this;
  }
}